import { replaceBreakableSpaces } from '../components/utils'

export const ADDRESS_DEBOUNCE_TIME = 150
export const INDUSTRY_DEBOUNCE_TIME = 250
export const MX_INSTITUTION_DEBOUNCE_TIME = 250

export const MIGRATION = 'migration'
export const MAJOR_ACCOUNT_CHANGE = 'major_account_change'
export const CREDIT_APPLICATION = 'creditApplication'
export const ADVANCED_ONBOARDING = 'advancedOnboarding'

// Keys used in local storage
export const DEAL_SOURCE_ID_KEY = 'deal_source_id'
export const AUTH_STORAGE_TOKEN_KEY = 'logged_in_user_token'
export const APPLICANT_EMAIL_KEY = 'applicant_email_key'
export const APPLICANT_AUTH_ID = 'applicant_auth_id'
export const SALESFORCE_CREDIT_IDS = 'salesforce_credit_ids'
export const APPLICANT_DATA_FROM_UP = 'applicant_data_from_up'
export const LOCAL_STORAGE_KEYS = {
  APPLICANT_AUTH_ID,
  APPLICANT_EMAIL_KEY,
  AUTH_STORAGE_TOKEN_KEY,
  DEAL_SOURCE_ID_KEY,
  SALESFORCE_CREDIT_IDS,
  APPLICANT_DATA_FROM_UP,
}
export const BDC_MODAL_VIEWED_KEY = 'bdc_modal_viewed'

/* When using these, implement by destructuring the values inside the object */
/* example: const { BILL_SPEND_AND_EXPENSE, BILL_DIVVY_CARD, BILL, DIVVY_PAY_LLC } = PRODUCT_CONST_STRINGS */
export const PRODUCT_CONST_STRINGS = {
  BILL_SPEND_AND_EXPENSE: 'BILL Spend & Expense',
  BILL_DIVVY_CARD: 'BILL Divvy Card',
  BILL: 'BILL',
  DIVVY_PAY_LLC: 'DIVVY PAY LLC',
}

export const PATHNAME_CONSTS = {
  RIGHT_FIT_SURVEY_PATH: '/right-fit-survey',
  AUTH_PATH: '/auth',
  MIGRATION_PATH: '/migration',
  GOOD_FIT_PATH: '/good-fit',
  NOT_GOOD_FIT_PATH: '/not-good-fit',
  VERIFY_EMAIL_PATH: '/verify-email',
  VERIFY_PASSWORD_PATH: '/verify-password',
  VERIFY_CODE_PATH: '/verify-code',
  MOBILE_DOCUMENT_UPLOAD_PATH: '/mobile-document-upload',
  SIGN_UP_PATH: '/signup',
  DASHBOARD_PATH: '/dashboard',
  MULTI_CREDIT_LINE_OFFER_PATH: '/credit-line-offers',
  MULTI_OFFER_TO_STANDARD_OFFER_PATH: '/credit-line-offers/standard-offer',
  MULTI_OFFER_TO_VARIABLE_OFFER_PATH: '/credit-line-offers/variable-offer',
  MULTI_OFFER_TO_SECURED_OFFER_PATH: '/credit-line-offers/secured-offer',
  SINGLE_CREDIT_LINE_OFFER_PATH: '/credit-line-offer',
  MANUAL_REVIEW_PATH: '/working-out-details',
  DUAL_OFFER_PATH: '/dual-offer',
  PRE_APPROVED_PATH: '/pre-approved',
  FROZEN_CREDIT_PATH: '/frozen-credit',
  DECLINED_PATH: '/declined',
  STANDARD_OFFER_PATH: 'standard-offer',
  VARIABLE_OFFER_PATH: 'variable-offer',
  SECURED_OFFER_PATH: 'secured-offer',
  PROCESSING_APPLICATION: 'processing-application',
  SUBMITTING_APPLICATION: 'submitting-application',
  REVIEWING_OFFER: 'reviewing-offer',
  ACCEPTING_OFFER: 'accepting-offer',
}

/*
 * Terms and services link all go to the same URL until we get the updated pdfs
 * J&S is the only thing currently supported for new customers
 */
export const URL = {
  APP_PROD: 'apply.divvy.co',
  BILL_PRIVACY_NOTICE: 'https://www.bill.com/privacy',
  BILL_REWARDS_LINK: 'http://bill.com/product/rewards',
  BILL_REWARDS_TERMS: 'https://www.bill.com/legal/bill-rewards-program',
  BILL_TERMS_AND_CONDITIONS: 'https://www.bill.com/legal/spend-expense-terms-of-service',
  CREDIT_IMPACT_LEARN_MORE: 'https://help.getdivvy.com/en/articles/5043115-divvy-credit-application-faq#h_8ce5a44308',
  ELIGIBILITY_REQUIREMENTS_LINK:
    'https://help.getdivvy.com/en/articles/6282738-what-are-the-eligibility-requirements-for-divvy-s-credit-application',
  DIVVY_PROD: 'https://app.divvy.co',
  TERMS_AND_SERVICES_BUS: 'https://app.divvy.co/assets/pdfs/divvy-signup-application-wex-business-only.pdf',
  TERMS_AND_SERVICES_CRB_BUS: 'https://app.divvy.co/assets/pdfs/bill-divvy-signup-application-dsa_crb_bo.pdf',
  TERMS_AND_SERVICES_CRB_JS: 'https://app.divvy.co/assets/pdfs/bill-divvy-signup-application-dsa_crb_js.pdf',
  TERMS_AND_SERVICES_JS: 'https://app.divvy.co/assets/pdfs/divvy-signup-application-wex-js.pdf',
  GOOGLE_TERMS_OF_SERVICE: 'https://policies.google.com/terms',
  GOOGLE_PRIVACY_POLICY: 'https://policies.google.com/privacy',
}

/**
 ** @deprecated use CreditApplicationStatus from '_gql'
 **/
export const CreditApplicationStatus = {
  ACCOUNT_READY: 'ACCOUNT_READY',
  APPROVED: 'APPROVED',
  AWAITING_SIGNATURE: 'AWAITING_SIGNATURE',
  BUILD_ACCOUNT: 'BUILD_ACCOUNT',
  CLOSED_AS_DUPLICATE: 'CLOSED_AS_DUPLICATE',
  CLOSED_LOST: 'CLOSED_LOST',
  COMPANY_BUILT: 'COMPANY_BUILT',
  CORRECTION_REQUESTED: 'CORRECTION_REQUESTED',
  CUSTOMER_CANCELED: 'CUSTOMER_CANCELED',
  EXPIRED: 'EXPIRED',
  FROZEN_CREDIT: 'FROZEN_CREDIT',
  IN_PROGRESS: 'IN_PROGRESS',
  IN_REVIEW: 'IN_REVIEW',
  MORE_INFO_NEEDED: 'MORE_INFO_NEEDED',
  NOT_STARTED: 'NOT_STARTED',
  OFFER_ACCEPTED: 'OFFER_ACCEPTED',
  OFFER_DECLINED: 'OFFER_DECLINED',
  REJECTED: 'REJECTED',
  REVIEW_OFFER: 'REVIEW_OFFER',
  SUBMITTED: 'SUBMITTED',
  VALIDATION_IN_PROGRESS: 'VALIDATION_IN_PROGRESS',
  VALIDATION_IN_REVIEW: 'VALIDATION_IN_REVIEW',
  VALIDATION_NOT_STARTED: 'VALIDATION_NOT_STARTED',
  VALIDATION_SUCCESSFUL: 'VALIDATION_SUCCESSFUL',
  MIGRATION_COMPLETE: 'MIGRATION_COMPLETE',
}

export const EDITABLE_STATUSES = ['AWAITING_SIGNATURE', 'IN_PROGRESS', 'CORRECTION_REQUESTED', 'NOT_STARTED']

export const LEGAL_BUSINESS_NAME = 'LEGAL_BUSINESS_NAME'
export const TAX_ID = 'TAX_ID'
export const AUTHORIZED_SIGNER = 'AUTHORIZED_SIGNER'
export const BENEFICIAL_OWNER_0 = 'BENEFICIAL_OWNER_0'
export const BENEFICIAL_OWNER_1 = 'BENEFICIAL_OWNER_1'
export const BENEFICIAL_OWNER_2 = 'BENEFICIAL_OWNER_2'
export const BENEFICIAL_OWNER_3 = 'BENEFICIAL_OWNER_3'

export const PAGE_NAME_STRINGS = {
  PAGE_AUTHORIZED_SIGNER: 'authorizedSigner',
  PAGE_BUSINESS_INFO: 'businessInfo',
  PAGE_COMPANY_OWNERS: 'companyOwners',
  PAGE_BENEFICIAL_OWNERS: 'beneficialOwners',
  PAGE_FINANCE_INFO: 'financeInfo',
  PAGE_REVIEW_AND_SIGN: 'reviewAndSign',
  PAGE_REVIEW_AND_VALIDATE: 'reviewAndValidate',
  PAGE_SIGNUP: 'signup',
  PAGE_GOOD_FIT: 'goodFit',
  PAGE_MIGRATION_SUCCESS: 'migrationSuccess',
  PAGE_DIVVY_PROGRAM_FIT: 'divvyProgramFit',
  PAGE_SIGN_IN: 'signIn',
  PAGE_NOT_GOOD_FIT: 'notGoodFit',
  PAGE_DASHBOARD: 'dashboard',
  PAGE_APPLICATION_SUBMITTED: 'applicationSubmitted',
  PAGE_RESET_PASSWORD: 'resetPassword',
  PAGE_VERIFY_CODE: 'verifyCode',
  PAGE_RIGHT_FIT_SURVEY: 'rightFitSurvey',
}

export const PartnerCodes = {
  GRHPPR: 'grhppr',
  INSBANK: 'insbank',
  KAPITUS: 'kapitus',
  WESTTOWN: 'westtown',
}

export const RightFitSurveyPageContent = [
  {
    checkBoxElements: [
      {
        label: 'less than 2 years',
        name: 'group0_element0',
      },
      {
        label: '2-4 years',
        name: 'group0_element1',
      },
      {
        label: '5-7 years',
        name: 'group0_element2',
      },
      {
        label: '8 or more years',
        name: 'group0_element3',
      },
    ],
    checkBoxSectionTitle: 'How many years has your company been in business?',
  },
  {
    checkBoxElements: [
      {
        label: 'less than $500k',
        name: 'group1_element0',
      },
      {
        label: '$500k-$2.5m',
        name: 'group1_element1',
      },
      {
        label: '$2.5m+',
        name: 'group1_element2',
      },
    ],
    checkBoxSectionTitle: 'What is your company’s annual revenue?',
  },
  {
    checkBoxElements: [
      {
        label: 'less than $25k',
        name: 'group2_element0',
      },
      {
        label: '$25k-$100k',
        name: 'group2_element1',
      },
      {
        label: '$100k-$300k',
        name: 'group2_element2',
      },
      {
        label: '$300k+',
        name: 'group2_element3',
      },
    ],
    checkBoxSectionTitle: 'What is the current cash balance in your company’s bank account?',
  },
  {
    checkBoxElements: [
      {
        label: 'less than 640',
        name: 'group3_element0',
      },
      {
        label: '640-700',
        name: 'group3_element1',
      },
      {
        label: '701-740',
        name: 'group3_element2',
      },
      {
        label: '741+',
        name: 'group3_element3',
      },
      {
        label: 'I don’t know/don’t wish to provide',
        name: 'group3_element4',
      },
    ],
    checkBoxSectionTitle: 'What is the estimated personal credit score of your company’s authorized signer?',
  },
]
