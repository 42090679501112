import { jwtDecode, type JwtPayload } from 'jwt-decode'
import { isDivvyAuthToken } from '../tokenUtils'

const JWT_TOKEN_TYPE = 'JWT'
const PASETO_TOKEN_TYPE = 'PASETO'

const pasetoVersionPurpose = 'v2.public.'

export interface Claims extends JwtPayload {
  issuer?: string
  company_divvy_uuid?: string
  credit_application_id?: string
  jwt_lookup_id?: string
  type?: string
}

/**
 * Decodes a JWT or PASETO token and returns the map of claims.
 * This does not validate the signature of a PASETO token.
 *
 * @param token - The JWT or PASETO token to decode.
 * @returns Claims - The map of claims from the given token.
 */
export const getDecodedToken = (token: string): Claims => {
  switch (tokenType(token)) {
    case PASETO_TOKEN_TYPE:
      return decodePaseto(token)
    case JWT_TOKEN_TYPE:
      return jwtDecode<Claims>(token)
    default:
      return {}
  }
}

export const getCompanyUuidFromToken = (token: string) => {
  if (!token || isDivvyAuthToken(token)) return null
  const decodedToken = getDecodedToken(token)
  return decodedToken?.company_divvy_uuid
}

export const getCreditAppIdFromToken = (token: string) => {
  if (!token || isDivvyAuthToken(token)) return null
  const decodedToken = getDecodedToken(token)

  return decodedToken?.credit_application_id
}

export const getJwtLookupIdFromToken = (token: string) => {
  if (!token || isDivvyAuthToken(token)) return null
  const decodedToken = getDecodedToken(token)

  return decodedToken?.jwt_lookup_id
}

export const getExpirationFromToken = (token: string) => {
  if (!token || isDivvyAuthToken(token)) return null
  const decodedToken = getDecodedToken(token)

  return decodedToken?.exp
}

export const getFormattedExpirationDateFromToken = (token: string) => {
  const expiration = getExpirationFromToken(token)

  if (!expiration) return null

  const date = new Date(0)
  date.setUTCSeconds(expiration)
  return date
}

export const getTypeFromToken = (token: string) => {
  if (!token || isDivvyAuthToken(token)) return null
  const decodedToken = getDecodedToken(token)
  return decodedToken?.type
}

export const isAccessToken = (token: string | null) => {
  if (!token) {
    return false
  }

  return getTypeFromToken(token) === 'access_token'
}

const tokenType = (token: string) => (token?.startsWith(pasetoVersionPurpose) ? PASETO_TOKEN_TYPE : JWT_TOKEN_TYPE)

const decodePaseto = (token: string): Claims => {
  const base64 = token.substring(pasetoVersionPurpose.length).replace(/-/g, '+').replace(/_/g, '/')
  const payload = atob(base64)
    .slice(0, -64)
    .split('')
    .map((c) => '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2))
    .join('')

  return JSON.parse(decodeURIComponent(payload)) as Claims
}
