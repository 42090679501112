import { useQuery } from '@apollo/client'
import useNameFormatter from '@divvy-web/hooks.usenameformatter'
import { FormattedMessage } from '@divvy-web/i18n'
import Dropdown from '@divvy-web/skylab.dropdown'
import { FormContext } from '@divvy-web/skylab.form'
import FormField from '@divvy-web/skylab.formfield'
import ListItem from '@divvy-web/skylab.listitem'
import TextInput from '@divvy-web/skylab.textinput'
import { css } from '@emotion/core'
import parsePhoneNumber, { AsYouType, getCountryCallingCode, isSupportedCountry } from 'libphonenumber-js'
import { Metadata } from 'libphonenumber-js/core'
import minMetadata from 'libphonenumber-js/min/metadata'
import { array, bool, node, string } from 'prop-types'
import React, { useCallback, useContext, useEffect, useMemo, useState } from 'react'
import GetCountryCodes from '../../pages/gql/GetCountryCodes.gql'
import { countriesMapper } from '../../utils/countryUtils'
import { getCountryFlag } from '../utils'

const PhoneNumberInput = ({ alwaysShowError, disabled, inputId, label, readOnly, supportedCountryCodes = [] }) => {
  const { getFormValue, validationErrors, setFormValue } = useContext(FormContext)
  const [getClassName, makeTestId] = useNameFormatter(inputId)
  const { data } = useQuery(GetCountryCodes)
  const [selectedCountry, setSelectedCountry] = useState(null)
  const [initiallySetCountryCode, setInitiallySetCountryCode] = useState(null)
  const [countrySearch, setCountrySearch] = useState(undefined)
  const [phoneNumberMaxLength, setPhoneNumberMaxLength] = useState(20)
  const [shouldFocusOnPhoneNumber, setShouldFocusOnPhoneNumber] = useState(true)
  const inputValue = getFormValue(inputId)
  const errors = validationErrors?.[inputId]?.[0]

  const phoneMetadata = useMemo(() => new Metadata(minMetadata), [])
  selectedCountry && phoneMetadata.selectNumberingPlan(selectedCountry.value)

  const selectedCountryCodeLength = selectedCountry?.label?.length ?? 7

  const getCountryLabel = useCallback(
    (countryCode, callingCode) => `${getCountryFlag(countryCode)} +${callingCode}`,
    [],
  )

  const countries = useMemo(
    () =>
      countriesMapper(data?.countryCodes)
        .filter((item) => {
          return supportedCountryCodes.length
            ? supportedCountryCodes.includes(item.value) && isSupportedCountry(item.value)
            : isSupportedCountry(item.value)
        })
        .map(({ label, value }) => {
          const callingCode = getCountryCallingCode(value)
          return {
            callingCode,
            id: value,
            label: getCountryLabel(value, callingCode),
            name: label,
            value,
          }
        })
        .filter((item) => item.callingCode),
    [data, supportedCountryCodes, getCountryLabel],
  )

  const getFieldValue = useCallback(() => {
    if (!initiallySetCountryCode) return ''

    const callingCodeLength = getCallingCodeLength(selectedCountry)
    const num = inputValue.slice(callingCodeLength)
    const formattedNum = new AsYouType(selectedCountry?.value).input(num)

    // This check handles a bug where the last character is a closing parenthesis user cannot delete it
    return formattedNum.endsWith(')') ? num : formattedNum
  }, [initiallySetCountryCode, inputValue, selectedCountry])

  const handleSelectCountry = (newCountry) => {
    const callingCodeLength = getCallingCodeLength(selectedCountry)
    const domesticNum = inputValue.slice(callingCodeLength)
    setSelectedCountry(newCountry)
    setFormValue(inputId, '+' + getSafeCallingCode(newCountry) + domesticNum)
  }

  const getCallingCodeLength = (country) => {
    const callingCode = country?.callingCode
    return callingCode ? callingCode.length + 1 : 0
  }

  const handleFieldChange = (event) => {
    setFormValue(inputId, '+' + getSafeCallingCode(selectedCountry) + event.target.value)
  }

  const getSafeCallingCode = (country) => country?.callingCode ?? '1'

  const exitCountrySearch = () => {
    if (countrySearch) {
      const countrySearchStr = countrySearch
      setCountrySearch(null)
      const callingCodeLength = getCallingCodeLength(selectedCountry)
      const domesticNum = inputValue.slice(callingCodeLength)
      const newCountry =
        countrySearchStr === '+1'
          ? countries.find((country) => country.value === (parsePhoneNumber('+1' + domesticNum)?.country ?? 'US'))
          : countries.find((country) => '+' + country.callingCode === countrySearchStr)

      if (newCountry) {
        setFormValue(inputId, '+' + getSafeCallingCode(newCountry) + domesticNum)
        setSelectedCountry(newCountry)
      }
    }
    if (shouldFocusOnPhoneNumber) focusOnNumberField()
  }

  const handleTabOut = (event) => {
    if (event.key === 'Tab') {
      setShouldFocusOnPhoneNumber(false)
    }
  }

  const focusOnNumberField = () => {
    if (document?.getElementById instanceof Function) {
      const numberField = document.getElementById(inputId)
      numberField?.focus && numberField.focus()
    }
  }

  useEffect(() => {
    if (initiallySetCountryCode && !countrySearch && phoneMetadata?.numberingPlan?.possibleLengths) {
      const lengthOfNonNumericChars = getFieldValue().match(/[^\d+]/g)?.length ?? 0
      setPhoneNumberMaxLength(Math.max(...phoneMetadata.numberingPlan.possibleLengths()) + lengthOfNonNumericChars)
    }
  }, [getFormValue, inputId, phoneMetadata, countrySearch, inputValue, getFieldValue, initiallySetCountryCode])

  useEffect(() => {
    if (!initiallySetCountryCode && countries.length) {
      const countryCode = parsePhoneNumber(inputValue)?.country
      const country = countries.find((country) => country.value === (countryCode ?? 'US'))
      setSelectedCountry(country)
      if (country && !inputValue.includes('+')) {
        const newFieldValue = '+' + getSafeCallingCode(country) + inputValue
        setFormValue(inputId, newFieldValue)
      }
      setInitiallySetCountryCode(true)
    }
  }, [countries, getFormValue, inputId, inputValue, setFormValue, initiallySetCountryCode])

  return (
    <FormField
      className={`${getClassName('form-field')} fs-unmask`}
      css={formFieldStyle({ selectedCountryCodeLength })}
      dataTestId={makeTestId('form-field')}
      disabled={disabled}
      inputId={inputId}
      label={label}
    >
      <div
        className='fs-mask'
        css={countryInputCss(readOnly)}
      >
        <Dropdown
          hideSearchIcon
          isSearchable
          disabled={disabled}
          inputValue={countrySearch}
          readOnly={readOnly}
          value={selectedCountry}
          onBlur={exitCountrySearch}
          onFocus={() => setShouldFocusOnPhoneNumber(true)}
          onInputValueChanged={(val) => setCountrySearch(val?.toLowerCase())}
          onKeyDown={handleTabOut}
          onSelect={handleSelectCountry}
        >
          {useCallback(
            ({ getItemProps, highlightedIndex }) => {
              const searchCountries = (countries) => {
                if (!countrySearch) return countries
                return countries.filter(({ callingCode, name, value }) =>
                  ('+' + callingCode + name + value).toLowerCase().includes(countrySearch),
                )
              }

              return searchCountries(countries).map((item, index) => (
                <ListItem
                  key={item.label}
                  isHighlighted={highlightedIndex === index}
                  label={item.label}
                  value={item.value}
                  {...getItemProps({
                    index,
                    item,
                  })}
                />
              ))
            },
            [countries, countrySearch],
          )}
        </Dropdown>
      </div>
      <TextInput
        alwaysShowError={alwaysShowError}
        className='fs-mask'
        dataTestId={makeTestId('text-input')}
        disabled={disabled}
        errorCaption={errors}
        hasError={!!errors}
        id={inputId}
        inputMode='numeric'
        maxLength={phoneNumberMaxLength}
        name={inputId}
        placeholder={
          readOnly ? (
            ''
          ) : (
            <FormattedMessage
              defaultMessage='Phone number'
              id='sputnik.PhoneNumberInput__jdJhOL'
            />
          )
        }
        readOnly={readOnly}
        value={getFieldValue()}
        onChange={handleFieldChange}
      />
    </FormField>
  )
}

PhoneNumberInput.propTypes = {
  alwaysShowError: bool,
  disabled: bool,
  inputId: string.isRequired,
  label: node.isRequired,
  readOnly: bool,
  supportedCountryCodes: array,
}

const formFieldStyle =
  ({ selectedCountryCodeLength }) =>
  ({ mq, skylab, type }) =>
    css`
      height: 56px;
      ${mq.xSmallMaxWidth({ height: 'var(--tri-space-900)' })}

      &-label {
        ${type.triFontDesktopLabelSmall}
        margin-bottom: 1px;
        color: var(--tri-color-text-tertiary);
      }

      .TextInput-input-wrapper {
        border-radius: 0;
        box-shadow: ${skylab.textInput.boxShadow} var(--tri-divider-color-border);
        border-radius: 0;

        input:-webkit-autofill {
          border-bottom: solid 1px var(--tri-divider-color-border);
          &:focus {
            border-bottom: solid 1px var(--tri-color-fill-primary-inverse);
          }
        }
      }

      .TextInput-input-wrapper-disabled {
        border: none;
        box-shadow: ${skylab.textInput.boxShadow} var(--tri-color-stroke-primary);
        input:-webkit-autofill {
          border-bottom: solid 1.5px var(--tri-color-stroke-primary);
        }
      }

      .TextInput-input-has-error {
        box-shadow: inset 0 -1px 0 var(--tri-color-text-danger);
        input:-webkit-autofill {
          border-bottom: solid 1.5px var(--tri-color-text-danger);
        }
      }

      .TextInput-error-caption {
        padding-left: 0;
        margin-left: 0;
        margin-top: var(--tri-space-50);
      }

      .TextInput-error-caption-read-only {
        margin-top: -2px;
      }

      .TextInput-placeholder {
        left: 128px;
        right: unset;
      }

      .TextInput-read-only .TextInput-input-wrapper {
        padding-left: ${(selectedCountryCodeLength - 1) * 8}px;
      }

      .TextInput-input {
        padding-left: var(--tri-space-1600);
        padding-right: 0;
      }

      [class*='PhoneNumber-form-field-value'] .SingleSelectDropdown-input-container-disabled {
        box-shadow: 0 1px;
      }
    `

const countryInputCss = (readOnly) => css`
  position: absolute;
  width: 120px !important; // unfortunately this is needed to fix a Firefox CSS issue
  padding-top: ${readOnly ? '2px' : '0'};

  ul {
    z-index: 6;
  }

  .SingleSelectDropdown-input-container {
    z-index: 1;
    min-width: unset;
    padding-left: 0;
    @supports (-webkit-touch-callout: none) {
      /* CSS specific to iOS devices since box-shadow is handled differently */
      box-shadow: inset 0 -1px 0 var(--tri-divider-color-border);
    }
  }

  .SingleSelectDropdown-menu-container {
    width: 100%;
  }
`

export default PhoneNumberInput
