import React from 'react'
import useNameFormatter from '@divvy-web/hooks.usenameformatter'
import { bool } from 'prop-types'
import { FormattedMessage } from '@divvy-web/i18n'
import { FormTextInput } from '@divvy-web/skylab.form'
import { contentStyles } from './styles'

const ResetPasswordContent = ({ isMobile = false }) => {
  const [getClassName, makeTestId] = useNameFormatter(isMobile ? 'ResetPasswordContentMobile' : 'ResetPasswordContent')

  return (
    <div
      className={getClassName('')}
      css={contentStyles}
    >
      <ul className={getClassName('reset-description-wrapper')}>
        <li>
          <FormattedMessage
            defaultMessage='Reset your password to avoid being locked out of your account.'
            id='sputnik.ResetPasswordContent__W6QzsU'
          />
        </li>
        <li>
          <FormattedMessage
            defaultMessage='Enter the email you use for BILL Spend & Expense and we’ll send instructions:'
            id='sputnik.ResetPasswordContent__nF6nP2'
          />
        </li>
      </ul>
      <div className={getClassName('email-address-field-row')}>
        <FormTextInput
          autoComplete='none'
          caption={
            <FormattedMessage
              defaultMessage='Likely your business email address'
              id='sputnik.ResetPasswordContent__HY2EJT'
            />
          }
          className='fs-mask'
          dataTestId={makeTestId('user-email-text-input')}
          label={
            <FormattedMessage
              defaultMessage='Email address'
              id='sputnik.ResetPasswordContent__hJZwTS'
            />
          }
          name='userEmail'
          placeholder={
            <FormattedMessage
              defaultMessage='Email address'
              id='sputnik.ResetPasswordContent__hJZwTS'
            />
          }
        />
      </div>
    </div>
  )
}

ResetPasswordContent.propTypes = { isMobile: bool }

export default ResetPasswordContent
