import React, { useState, useEffect, useCallback } from 'react'
import { useQuery } from '@apollo/client'
import { useLocation, useNavigate, useParams, useSearchParams } from 'react-router-dom'
import { CreditApplicationStatus } from '_gql'
import GetDecisions from '../../pages/gql/GetDecisions.gql'
import GetApplicationStatus from '../../pages/gql/GetApplicationStatus.gql'
import useDivvyUUID from '../../hooks/useDivvyUUID'
import { PATHNAME_CONSTS } from '../../resources/constants'
import useApplicantTokenOnMount from '../../hooks/useApplicantTokenOnMount'
import ReviewingOfferPollingPageContent from './ReviewingOfferPollingPageContent'

const CREDIT_DECISION_POLLING_MAX_TIMEOUT = window.__DivvyEnvironment.PROCESSING_MAX_TIMEOUT || 500
const STATUS_POLLING_MAX_TIMEOUT = 120000
const POLLING_RATE = 3000

const calculateStatusTimeoutRemaining = (pollingForStatusSince) =>
  STATUS_POLLING_MAX_TIMEOUT - (new Date().getTime() - pollingForStatusSince)

const ReviewingOfferPollingPage = () => {
  /* make sure we have a token before we start polling */
  useApplicantTokenOnMount()
  const navigate = useNavigate()
  const { pathname, search: urlSearch } = useLocation()
  const { appId } = useParams()
  const appPath = `/app/${appId}/section/processed`
  const [search] = useSearchParams()
  const pollingForStatusSince = search?.get('pollingForStatusSince')
    ? parseInt(search?.get('pollingForStatusSince') as string)
    : null

  const [companyDivvyUuid] = useDivvyUUID()
  const [creditDecisionTimer, setCreditDecisionTimer] = useState(0)
  const [statusTimer, setStatusTimer] = useState(0)

  const {
    data: decisionsData,
    startPolling: startPollingForCreditDecision,
    stopPolling: stopPollingForCreditDecision,
  } = useQuery(GetDecisions, {
    fetchPolicy: 'no-cache',
    skip: !companyDivvyUuid || !!pollingForStatusSince,
    variables: { companyId: companyDivvyUuid },
  })

  const {
    data: statusData,
    loading: isLoadingStatusData,
    startPolling: startPollingForStatus,
    stopPolling: stopPollingForStatus,
  } = useQuery(GetApplicationStatus, {
    fetchPolicy: 'no-cache',
    variables: { creditApplicationId: appId },
  })
  // status data
  const { creditApplication } = statusData || {}
  const status = creditApplication?.status
  const isReviewOfferStatus = statusData && status === CreditApplicationStatus.REVIEW_OFFER

  // decisions data
  const decisions = decisionsData?.decisions
  const underwritingDeclined = decisionsData?.underwritingDeclined
  const hasSingleDecision = decisions?.length === 1
  const hasMultipleDecisions = decisions?.length >= 2
  const finishedPollingForCreditDecision =
    creditDecisionTimer >= CREDIT_DECISION_POLLING_MAX_TIMEOUT || decisions?.length > 0 || underwritingDeclined

  const handleSubmissionError = useCallback(() => {
    stopPollingForStatus()
    window.sessionStorage.removeItem('submissionPendingUrl')
    navigate(`/app/${appId}/section/submission-error`)
  }, [appId, navigate, stopPollingForStatus])

  // save url to redirect back to it in case user leaves this page
  useEffect(() => {
    if (urlSearch.includes('pollingForStatusSince')) {
      //TODO: can we use something else than useLocation?
      window.sessionStorage.setItem('submissionPendingUrl', pathname + urlSearch)
    }
  }, [appId, pathname, pollingForStatusSince, urlSearch])

  // handle polling for status
  useEffect(() => {
    if (pollingForStatusSince) {
      startPollingForStatus(POLLING_RATE)
      if (statusTimer >= calculateStatusTimeoutRemaining(pollingForStatusSince)) {
        handleSubmissionError()
      } else {
        setTimeout(() => setStatusTimer(statusTimer + POLLING_RATE), POLLING_RATE)
      }
    }
  }, [handleSubmissionError, pollingForStatusSince, startPollingForStatus, statusTimer])

  // handle polling for credit decision
  useEffect(() => {
    if (!pollingForStatusSince) {
      startPollingForCreditDecision(POLLING_RATE)
      if (creditDecisionTimer >= CREDIT_DECISION_POLLING_MAX_TIMEOUT) {
        stopPollingForCreditDecision()
      } else {
        setTimeout(() => setCreditDecisionTimer(creditDecisionTimer + POLLING_RATE), POLLING_RATE)
      }
    }
  }, [creditDecisionTimer, pollingForStatusSince, startPollingForCreditDecision, stopPollingForCreditDecision])

  useEffect(() => {
    if (finishedPollingForCreditDecision && !isLoadingStatusData && statusData) {
      window.sessionStorage.removeItem('submissionPendingUrl')
      if (hasMultipleDecisions && isReviewOfferStatus) {
        navigate(`${appPath}${PATHNAME_CONSTS.MULTI_CREDIT_LINE_OFFER_PATH}`, { replace: false })
      } else if (hasSingleDecision && isReviewOfferStatus) {
        navigate(`${appPath}${PATHNAME_CONSTS.SINGLE_CREDIT_LINE_OFFER_PATH}`, { replace: false })
      } else {
        navigate(`${appPath}${PATHNAME_CONSTS.MANUAL_REVIEW_PATH}`, { replace: false })
      }
    }
  }, [
    appPath,
    finishedPollingForCreditDecision,
    hasMultipleDecisions,
    hasSingleDecision,
    isLoadingStatusData,
    isReviewOfferStatus,
    navigate,
    statusData,
  ])

  return <ReviewingOfferPollingPageContent />
}

export default ReviewingOfferPollingPage
