import React from 'react'
import { useNavigate } from 'react-router-dom'
import { TOAST_TYPE_BASIC, useToast } from '@divvy-web/skylab.toast'
import { FormattedMessage } from '@divvy-web/i18n'
import { bool } from 'prop-types'
import useNameFormatter from '@divvy-web/hooks.usenameformatter'
import BasicButton, { BASIC_BUTTON_TYPE_OUTLINED } from '@divvy-web/skylab.basicbutton'
import ImageWrapper from '../../components/ImageWrapper'
import { useAuth } from '../../auth'
import CustomerAssistanceButton from '../../components/CustomerAssistanceButton/CustomerAssistanceButton'
import OneColorSidebar from '../../components/OneColorSidebar/OneColorSidebar'
import { PATHNAME_CONSTS } from '../../resources/constants'
import { ApplicationDeclinedContainer } from './applicationDeclinedStyles'

export const ApplicationDeclined = ({ hasApplications }) => {
  const navigate = useNavigate()
  const { logout } = useAuth()
  const showToast = useToast()
  const [getClassName, makeTestId] = useNameFormatter('ApplicationDeclined')

  const officeDeskPath = 'https://app.divvy.co/assets/illustrations/office-desk-10-01'
  const officeDeskSrcSet = `${officeDeskPath}@XL.png, ${officeDeskPath}@3x.png, ${officeDeskPath}@2x.png, ${officeDeskPath}@1x.png`

  const handleDivvyDashboardClick = () => {
    navigate(PATHNAME_CONSTS.DASHBOARD_PATH)
  }

  const handleLogoutClick = () => {
    logout()
    showToast(
      TOAST_TYPE_BASIC,
      <FormattedMessage
        defaultMessage='Log out successdul!'
        id='sputnik.ApplicationDeclined__419Ggg'
      />,
      {
        autoHideDelay: 5000,
        dataTestId: 'logout-success-toast',
      },
    )
  }

  return (
    <>
      <div css={ApplicationDeclinedContainer}>
        <CustomerAssistanceButton />
        <div className='main-container fs-unmask'>
          <main className={getClassName('page-content')}>
            <div
              className={getClassName('body')}
              data-testid={makeTestId('')}
            >
              {/* The image directly below is only rendered once the mobile media query is hit */}
              <div
                className={getClassName('application-declined-mobile-image-div')}
                data-testid={makeTestId('application-declined-mobile-image-div')}
              >
                <ImageWrapper
                  alt='person-working-at-desk'
                  className='application-declined-mobile-image'
                  imageName='application-declined-mobile'
                />
              </div>
              <p
                className={getClassName('title')}
                data-testid={makeTestId('title')}
              >
                <FormattedMessage
                  defaultMessage="We're unable to offer you a credit line at this time"
                  id='sputnik.ApplicationDeclined__VZ749C'
                />
              </p>
              <p
                className={getClassName('body-copy')}
                data-testid={makeTestId('body-copy')}
              >
                <FormattedMessage
                  defaultMessage="Look out for an email with more details about this decision. We appreciate you considering us and hope you'll return to apply again in the future."
                  id='sputnik.ApplicationDeclined__baLQtO'
                />
              </p>
              {hasApplications && (
                <div
                  className={getClassName('has-other-applications-buttons')}
                  data-testid={makeTestId('has-other-applications-buttons')}
                >
                  <BasicButton
                    className={getClassName('dashboard')}
                    dataTestId={makeTestId('dashboard')}
                    onClick={handleDivvyDashboardClick}
                  >
                    <FormattedMessage
                      defaultMessage='Back to dashboard'
                      id='sputnik.ApplicationDeclined__bpXgxI'
                    />
                  </BasicButton>
                  <BasicButton
                    className={getClassName('close-and-logout')}
                    dataTestId={makeTestId('close-and-logout')}
                    type={BASIC_BUTTON_TYPE_OUTLINED}
                    onClick={handleLogoutClick}
                  >
                    <FormattedMessage
                      defaultMessage='Close & logout'
                      id='sputnik.ApplicationDeclined__XVpGMa'
                    />
                  </BasicButton>
                </div>
              )}
              {!hasApplications && (
                <div
                  className={getClassName('has-no-other-applications-button')}
                  data-testid={makeTestId('has-no-other-applications-button')}
                >
                  <BasicButton
                    className={getClassName('close-and-logout-no-other-apps')}
                    dataTestId={makeTestId('close-and-logout-no-other-apps')}
                    onClick={handleLogoutClick}
                  >
                    <FormattedMessage
                      defaultMessage='Close & logout'
                      id='sputnik.ApplicationDeclined__XVpGMa'
                    />
                  </BasicButton>
                </div>
              )}
            </div>
          </main>
        </div>
        <OneColorSidebar srcImage={officeDeskSrcSet} />
      </div>
    </>
  )
}

ApplicationDeclined.propTypes = {
  hasApplications: bool,
}

export default ApplicationDeclined
