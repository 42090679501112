import React, { useState } from 'react'
import { FormattedMessage } from '@divvy-web/i18n'
import useNameFormatter from '@divvy-web/hooks.usenameformatter'
import { useNavigate } from 'react-router-dom'
import Form, { FormConsumer } from '@divvy-web/skylab.form'
import { TOAST_TYPE_SUCCESS, useToast } from '@divvy-web/skylab.toast'
import { css } from '@emotion/core'
import BasicButton, { BASIC_BUTTON_TYPE_FLAT } from '@divvy-web/skylab.basicbutton'
import MiscPageWrapper from '../../components/MiscPageWrapper/MiscPageWrapper'
import PageViewWrapper from '../../components/PageViewWrapper/PageViewWrapper'
import { MaskedPasswordInput, AutoCompleteDisable } from '../../components/FormInputs'
import PasswordTipsContainer from '../../components/PasswordTipsContainer'
import passwordValidations from '../../components/CreatePasswordContainer/passwordValidations'
import { useAuth } from '../../auth'
import { PAGE_NAME_STRINGS, PATHNAME_CONSTS } from '../../resources/constants'

const initialValues = {
  confirmPassword: '',
  confirmPasswordDisplay: '',
  password: '',
  passwordDisplay: '',
}

const ResetPassword = () => {
  const [getClassName, makeTestId] = useNameFormatter('ResetPassword')
  const [isPasswordTipsShowing, setIsPasswordTipsShowing] = useState(false)
  const [isSubmitting, setIsSubmitting] = useState(false)
  const { handleResetAuthPassword } = useAuth()
  const showToast = useToast()
  const navigate = useNavigate()

  const handlePasswordTipsCloseClick = () => setIsPasswordTipsShowing(false)
  const handleNeedTipsClick = () => setIsPasswordTipsShowing(true)
  const handlePreviousClick = () => navigate({ pathname: PATHNAME_CONSTS.AUTH_PATH })

  const onSubmitResetPasswordComplete = () => {
    showToast(
      TOAST_TYPE_SUCCESS,
      <FormattedMessage
        defaultMessage='Password successfully updated!'
        id='sputnik.ResetPassword__ymkAf4'
      />,
      {
        autoHideDelay: 5000,
        dataTestId: makeTestId('password-updated'),
      },
    )
    navigate(PATHNAME_CONSTS.VERIFY_PASSWORD_PATH)
  }

  const handleSubmit = (password) => {
    handleResetAuthPassword({
      newPassword: password,
      onComplete: onSubmitResetPasswordComplete,
      setIsSubmitting,
      showToast,
      isCreatingNewPassword: false,
    })
  }

  return (
    <div css={resetPasswordWrapperStyles}>
      <PageViewWrapper pageName={PAGE_NAME_STRINGS.PAGE_RESET_PASSWORD}>
        <MiscPageWrapper
          headingStyles={null}
          pageName={PAGE_NAME_STRINGS.PAGE_RESET_PASSWORD}
          pageStyles={null}
          pageTitle={
            <FormattedMessage
              defaultMessage='Reset your password'
              id='sputnik.ResetPassword__p+kmJg'
            />
          }
        >
          <div className={getClassName('password-form-wrapper')}>
            <Form
              initialValues={initialValues}
              validation={passwordValidations}
              onSubmit={(_, formValues) => handleSubmit(formValues?.password)}
            >
              <div className={getClassName('password-fields-wrapper')}>
                <MaskedPasswordInput
                  autoComplete='none'
                  className='fs-mask'
                  dataTestId={makeTestId('password')}
                  label={
                    <FormattedMessage
                      defaultMessage='Enter your password'
                      id='sputnik.ResetPassword__2GFjIN'
                    />
                  }
                  name='passwordDisplay'
                  placeholder={
                    <FormattedMessage
                      defaultMessage='Password'
                      id='sputnik.ResetPassword__5sg7KC'
                    />
                  }
                />
                <MaskedPasswordInput
                  shouldDisablePaste
                  autoComplete='none'
                  className='fs-mask'
                  dataTestId={makeTestId('confirm-password')}
                  label={
                    <FormattedMessage
                      defaultMessage='Confirm password'
                      id='sputnik.ResetPassword__8HimVK'
                    />
                  }
                  name='confirmPasswordDisplay'
                  placeholder={
                    <FormattedMessage
                      defaultMessage='Confirm password'
                      id='sputnik.ResetPassword__8HimVK'
                    />
                  }
                />
              </div>
              <FormConsumer>
                {({ validationErrors }) => (
                  <>
                    <div className={getClassName('button-wrapper')}>
                      <BasicButton
                        buttonType='submit'
                        className={getClassName('save')}
                        dataTestId={makeTestId('save')}
                        disabled={!!validationErrors || isSubmitting}
                        showSpinner={isSubmitting}
                      >
                        <FormattedMessage
                          defaultMessage='Save'
                          id='sputnik.ResetPassword__jvo0vs'
                        />
                      </BasicButton>
                      <BasicButton
                        dataTestId={makeTestId('need-tips')}
                        type={BASIC_BUTTON_TYPE_FLAT}
                        onClick={handleNeedTipsClick}
                      >
                        <FormattedMessage
                          defaultMessage='Need tips?'
                          id='sputnik.ResetPassword__OH1TgU'
                        />
                      </BasicButton>
                    </div>
                    <div className={getClassName('mobile-button-wrapper')}>
                      <BasicButton
                        className={getClassName('previous')}
                        dataTestId={makeTestId('previous')}
                        type={BASIC_BUTTON_TYPE_FLAT}
                        onClick={handlePreviousClick}
                      >
                        <FormattedMessage
                          defaultMessage='Previous'
                          id='sputnik.ResetPassword__JJNc3c'
                        />
                      </BasicButton>
                      <BasicButton
                        buttonType='submit'
                        className={getClassName('next')}
                        dataTestId={makeTestId('next')}
                        disabled={!!validationErrors}
                      >
                        <FormattedMessage
                          defaultMessage='Next'
                          id='sputnik.ResetPassword__9+Ddtu'
                        />
                      </BasicButton>
                    </div>
                  </>
                )}
              </FormConsumer>
              <AutoCompleteDisable />
            </Form>
          </div>
          <PasswordTipsContainer
            handleCloseClick={handlePasswordTipsCloseClick}
            isShowing={isPasswordTipsShowing}
          />
        </MiscPageWrapper>
      </PageViewWrapper>
    </div>
  )
}

export default ResetPassword

const resetPasswordWrapperStyles = ({ mq }) => css`
  .ResetPassword-page-heading .heading-wrapper {
    padding: var(--tri-space-3000) 0 var(--tri-space-500) var(--tri-space-3000);
    ${mq.xSmallMaxWidth({
      padding: 'var(--tri-space-500) var(--tri-space-300)',
    })}
  }
  .ResetPassword-page-heading-pro-tips-mobile {
    display: none;
  }
  .page-wrapper-content .ResetPassword-password-form-wrapper {
    padding-left: var(--tri-space-1500);
    ${mq.xSmallMaxWidth({ paddingLeft: '0', paddingTop: 'var(--tri-space-500)' })}
    .ResetPassword-password-fields-wrapper {
      display: flex;
      flex-direction: column;
      gap: var(--tri-space-400);
      .FormField {
        width: 512px;
        ${mq.xSmallMaxWidth({ width: '256px' })}
      }
    }

    .ResetPassword-button-wrapper {
      .ResetPassword-save {
        ${mq.xxSmallMaxWidth({ display: 'none' })}
      }
      display: flex;
      margin-top: var(--tri-space-500);
      gap: var(--tri-space-400);
    }

    .ResetPassword-mobile-button-wrapper {
      ${mq.xSmallMinWidth({ display: 'none' })}
      display: flex;
      height: 88px;
      position: fixed;
      width: 100%;
      left: 0;
      bottom: 0;
      margin-top: var(--tri-space-100);
      .BasicButton {
        border-radius: 0;
        width: 100%;
      }
    }
  }
`
