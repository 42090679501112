import React from 'react'
import { FormattedMessage } from '@divvy-web/i18n'
import PollingPageContent from '../../../components/PollingPageContent/PollingPageContent'

const ReviewingOfferPollingPageContent = () => {
  return (
    <PollingPageContent
      mainText={
        <FormattedMessage
          defaultMessage='Reviewing your offer(s)'
          id='sputnik.ReviewingOfferPollingPageContent__EG/2X3'
        />
      }
      subText={
        <FormattedMessage
          defaultMessage='This can take a minute or two. Thanks for your patience.'
          id='sputnik.ReviewingOfferPollingPageContent__zrXnI+'
        />
      }
    />
  )
}

export default ReviewingOfferPollingPageContent
