import React, { useState, useMemo } from 'react'
import { debounce } from '@divvy-web/utils'
import { useLazyQuery } from '@apollo/client'
import Icon from '@divvy-web/skylab.icon'
import useOnClickOutside from '@divvy-web/hooks.useonclickoutside'
import { FormTextInput, useForm } from '@divvy-web/skylab.form'
import ListItem from '@divvy-web/skylab.listitem'
import { css } from '@emotion/core'
import { FormattedMessage } from '@divvy-web/i18n'
import { bool } from 'prop-types'
import { INDUSTRY_DEBOUNCE_TIME } from '../../resources/constants'
import FormElementWrapper from '../FormInputs/FormElementWrapper.js'
import searchNAICSQuery from './searchNaics.gql'

const IndustryAutoComplete = ({ disabled, isReview, readOnly }) => {
  const { getFormValue, setFormValue } = useForm()
  const [searchNAICS, { data }] = useLazyQuery(searchNAICSQuery)
  const [showOptions, setShowOptions] = useState(false)

  let suggestions = useMemo(
    () =>
      getFormValue('industry', '')
        ? [].concat(data?.naicsCodes || []).sort((industry1, industry2) => {
            if (industry1.label > industry2.label) return 1
            if (industry1.label < industry2.label) return -1
            return 0
          })
        : [],
    [data?.naicsCodes, getFormValue],
  )

  const debouncedSearchNaics = useMemo(
    () =>
      debounce((value) => {
        searchNAICS({ variables: { query: value } })
      }, INDUSTRY_DEBOUNCE_TIME),
    [searchNAICS],
  )

  const handleChange = (value) => {
    if (!showOptions) setShowOptions(true)
    setFormValue('industry', value)
    value && debouncedSearchNaics(value)
  }

  const optionsClick = (value, code) => {
    setFormValue('industry', value)
    setFormValue('naicsCode', code)
    setShowOptions(false)
  }

  const refOutsideClick = useOnClickOutside(() => {
    if (!suggestions.map(({ title }) => title).includes(getFormValue('industry')) && !readOnly && showOptions) {
      setFormValue('industry', undefined)
      setFormValue('naicsCode', undefined)
    }
    setShowOptions(false)
  })

  const handleCaretClick = () => {
    if (showOptions) {
      setShowOptions(false)
    } else {
      getFormValue('industry') && debouncedSearchNaics(getFormValue('industry'))
      setShowOptions(true)
    }
  }

  const getIcon = () => {
    if (readOnly) return null

    let caretDirection = null
    if (showOptions && suggestions.length) caretDirection = 'caretUp'
    if (!showOptions && getFormValue('industry')) caretDirection = 'caretDown'

    if (caretDirection) {
      return (
        <button
          disabled={disabled}
          type='button'
          onClick={handleCaretClick}
        >
          <Icon name={caretDirection} />
        </button>
      )
    }
    return null
  }

  const handleBlur = () => {
    if (showOptions && !suggestions.length) {
      setFormValue('industry', undefined)
      setFormValue('naicsCode', undefined)
    }
  }

  return (
    <div
      ref={refOutsideClick}
      className='signup-industry-selection'
      css={industryFieldStyles}
    >
      <FormElementWrapper>
        <FormTextInput
          caption={
            isReview ? null : (
              <FormattedMessage
                defaultMessage='Please start typing to select from suggested industries.'
                id='sputnik.IndustryAutoComplete__fc/0jV'
              />
            )
          }
          className='industry-field'
          disabled={disabled}
          label='Industry'
          name='industry'
          placeholder='Industry'
          readOnly={readOnly}
          rightIcon={getIcon()}
          onBlur={handleBlur}
          onChange={(e) => handleChange(e.target.value)}
          onFocus={() => setShowOptions(true)}
        />
      </FormElementWrapper>
      {showOptions && !!suggestions.length && (
        <div className='options-container'>
          {suggestions.map((suggestion) => {
            const { naicsCode, title: value } = suggestion
            return (
              <ListItem
                key={naicsCode}
                label={value}
                value={value}
                onClick={() => optionsClick(value, naicsCode)}
              />
            )
          })}
        </div>
      )}
    </div>
  )
}

IndustryAutoComplete.propTypes = { disabled: bool, isReview: bool, readOnly: bool }

export default IndustryAutoComplete

const industryFieldStyles = ({ colors, mq }) => css`
  position: relative;

  .options-container {
    position: absolute;
    overflow-y: auto;
    max-height: 320px;
    background: var(--tri-color-fill-primary);
    border: 1px solid var(--tri-color-text-secondary-inverse);
    box-shadow: 0 var(--tri-space-100) var(--tri-space-400) ${colors.fade('var(--tri-color-fill-primary-inverse)', 0.1)};
    width: 100%;
    margin-top: -1px;
    z-index: 6;

    ${mq.xSmallMaxWidth({ marginTop: '-18px' })}
  }

  span {
    right: 0;
  }

  [class*='Icon-caret'] {
    color: var(--tri-color-text-primary);
    max-width: 20px;
    max-height: 20px;
  }

  .TextInput-input-wrapper-has-right-icon span {
    right: 0;
  }
`
