import { css } from '@emotion/core'

export const mobilePollingPageContentCss = ({ mq, type }) => css`
  margin: 0;
  padding: 0;
  height: 100%;
  display: flex;
  flex-direction: column;
  position: relative;
  overflow-x: hidden;

  .main-content-section {
    flex: 2;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    .body-text {
      display: flex;
      flex-direction: column;
      text-align: center;
      justify-content: center;
      align-items: center;
      width: 75%;
    }

    .title {
      ${type.triFontDesktopTitleSmallEmphasis};
      margin-top: var(--tri-space-300);
      margin-bottom: unset;
      text-align: center;
    }

    .subtitle {
      ${type.triFontDesktopBodyMedium};
      color: var(--tri-color-text-secondary);
      margin: var(--tri-space-250) 0 var(--tri-space-300);
      text-align: center;
    }
  }

  .sidebar-image {
    position: absolute;
    bottom: 87px;
    ${mq.xSmallMinWidth({
      maxWidth: '440px',
      right: '-90px',
    })}
    ${mq.mediumMinWidth({
      maxWidth: '625px',
      right: '-130px',
    })}
      ${mq.largeMinWidth({
      maxWidth: '700px',
      right: '-145px',
    })};
    ${mq.xLargeMinWidth({
      maxWidth: '850px',
      right: '-200px',
    })};
    ${mq.xxLargeMinWidth({
      maxWidth: '1076px',
      right: '-220px',
    })};
  }

  // This className is defined in the ImageWrapper component in src/components/ImageWrapper
  .program-image {
    max-width: 213px;
    margin-bottom: var(--tri-space-400);
  }
`

export const webPollingPageContentCss = ({ mq, type }) => css`
  margin: 0;
  padding: 0;
  height: 100%;
  display: flex;
  flex-direction: row;
  position: relative;
  overflow-x: hidden;

  .main-content-section {
    flex: 2;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    .body-text {
      text-align: center;
      width: 50%;
    }

    .title {
      ${type.triFontDesktopTitleLargeEmphasis};
      margin-top: var(--tri-space-300);
      margin-bottom: unset;
    }

    .subtitle {
      ${type.triFontDesktopBodyLarge};
      color: var(--tri-color-text-secondary);
      margin-top: var(--tri-space-150);
    }
  }

  .sidebar-image {
    position: absolute;
    bottom: 87px;
    ${mq.xSmallMinWidth({
      maxWidth: '440px',
      right: '-90px',
    })}
    ${mq.mediumMinWidth({
      maxWidth: '625px',
      right: '-130px',
    })}
      ${mq.largeMinWidth({
      maxWidth: '700px',
      right: '-145px',
    })};
    ${mq.xLargeMinWidth({
      maxWidth: '850px',
      right: '-200px',
    })};
    ${mq.xxLargeMinWidth({
      maxWidth: '1076px',
      right: '-220px',
    })};
  }

  // This className is defined in the ImageWrapper component in src/components/ImageWrapper
  .program-image {
    max-width: 213px;
    margin-bottom: var(--tri-space-400);
  }
`
