import React, { useContext } from 'react'
import { useNavigate, useParams, useLocation } from 'react-router-dom'
import { useMutation } from '@apollo/client'
import { number } from 'prop-types'
import BasicButton, { BASIC_BUTTON_TYPE_FLAT } from '@divvy-web/skylab.basicbutton'
import { FormattedMessage, FormattedCurrency } from '@divvy-web/i18n'
import useNameFormatter from '@divvy-web/hooks.usenameformatter'
import { TOAST_TYPE_DANGER, useToast } from '@divvy-web/skylab.toast'
import CustomerAssistanceButton from '../../components/CustomerAssistanceButton/CustomerAssistanceButton'
import OneColorSidebar from '../../components/OneColorSidebar/OneColorSidebar'
import SelectedDecision from '../gql/mutations/SelectedDecision.gql'
import { DecisionContext } from '../ApplicationProcessed/DecisionsContext'
import ImageWrapper from '../../components/ImageWrapper'
import { PATHNAME_CONSTS } from '../../resources/constants'
import { StandardCreditLineOfferCss } from './standardCreditLineOfferStyles'

const StandardCreditLineOffer = ({ desiredCredit }) => {
  const navigate = useNavigate()
  const location = useLocation()
  const { appId } = useParams()
  const [getClassName, makeTestId] = useNameFormatter('StandardCreditLineOffer')
  const [selectedDecision] = useMutation(SelectedDecision)
  const showToast = useToast()
  const { decisions } = useContext(DecisionContext)
  const invalidMethods = new Set(['auto_cash_underwriting', 'manual_cash_underwriting', 'secured_line'])
  const standardDecision = decisions?.filter((decision) => invalidMethods.has(decision.underwritingMethod) === false)
  const approvedAmount = standardDecision?.[0]?.approvedAmountInCents
  const isCrossSell = standardDecision?.[0]?.underwritingMethod === 'cross_sell'
  const isCounterOffer = approvedAmount !== desiredCredit
  const decisionId = standardDecision?.[0]?.id

  const businessTransactionPath = 'https://app.divvy.co/assets/illustrations/business-transaction-01'
  const businessTransactionSrcSet = `${businessTransactionPath}@3x.png, ${businessTransactionPath}@2x.png, ${businessTransactionPath}@1x.png`
  const counterOfferPath = 'https://app.divvy.co/assets/illustrations/gift-02'
  const counterOfferSrcSet = `${counterOfferPath}@3x.png, ${counterOfferPath}@2x.png, ${counterOfferPath}@1x.png`

  const sidebarImage = isCounterOffer ? counterOfferSrcSet : businessTransactionSrcSet

  const showErrorToast = (message) => {
    showToast(TOAST_TYPE_DANGER, message, { autoHideDelay: 5000 })
  }

  const handleAcceptClick = () => {
    selectedDecision({
      variables: {
        id: decisionId,
        salesforceCreditId: appId,
      },
    })
      .then(() => {
        navigate('next-steps')
      })
      .catch(() =>
        showErrorToast(
          <FormattedMessage
            defaultMessage='Error accepting pre approved amount.'
            id='sputnik.StandardCreditLineOffer__cxmoLn'
          />,
        ),
      )
  }

  const handleBackToDashboardClick = () => {
    navigate(PATHNAME_CONSTS.DASHBOARD_PATH)
  }

  const handlePreviousClick = () => {
    navigate(-1)
  }

  const isComingFromDualOffer = location.pathname.includes('dual-offer')

  return (
    <>
      <div css={StandardCreditLineOfferCss}>
        <CustomerAssistanceButton />
        <div className='main-container fs-unmask'>
          <main className={getClassName('page-content')}>
            <div
              className={getClassName('body')}
              data-testid={makeTestId('')}
            >
              <p
                className={getClassName('title')}
                data-testid={makeTestId('title')}
              >
                <FormattedMessage
                  defaultMessage="{isCounterOffer, select,
                    true {We've got an offer for you!}
                    other {{isCrossSell, select,
                      true {Congrats! Your offer is ready.}
                      other {Congrats! You're pre-approved.}
                    }}
                  }"
                  id='sputnik.StandardCreditLineOffer__6jJMxy'
                  values={{
                    isCounterOffer,
                    isCrossSell,
                  }}
                />
              </p>
              {/* The image directly below is only to be rendered once the mobile media query is hit */}
              <div>
                {isCounterOffer && (
                  <section
                    className={getClassName('mobile-splash-businessTransaction-image-div')}
                    data-testid={makeTestId('mobile-splash-counter-offer-image')}
                  >
                    <ImageWrapper
                      alt='giving-gift'
                      className={getClassName('mobile-splash-counter-offer-image')}
                      imageName='counter-offer-mobile'
                    />
                  </section>
                )}
                {!isCounterOffer && (
                  <section
                    className={getClassName('mobile-splash-businessTransaction-image-div')}
                    data-testid={makeTestId('mobile-splash-businessTransaction-image')}
                  >
                    <ImageWrapper
                      alt='business-transaction'
                      className={getClassName('mobile-splash-business-transaction-image')}
                      imageName='credit-line-offer-mobile'
                    />
                  </section>
                )}
              </div>
              <p className={getClassName('subtitle')}>
                <FormattedMessage
                  defaultMessage='Standard credit line:'
                  id='sputnik.StandardCreditLineOffer__UZgb4A'
                />
              </p>
              <div
                className={getClassName('approved-amount')}
                data-testid={makeTestId('approved-amount')}
              >
                <FormattedCurrency
                  maximumFractionDigits={0}
                  minimumFractionDigits={2}
                  value={approvedAmount}
                />
              </div>
              <div
                className={getClassName('approved-amount-caption')}
                data-testid={makeTestId('approved-caption')}
              >
                {isCounterOffer && (
                  <p
                    className='counter-offer-details'
                    data-testid={makeTestId('counter-offer-details')}
                  >
                    <FormattedMessage
                      defaultMessage='With the information provided in the application we were unable to approve you for the requested amount of {desiredCredit}.'
                      id='sputnik.StandardCreditLineOffer__BSHMT4'
                      values={{
                        desiredCredit: (
                          <FormattedCurrency
                            maximumFractionDigits={0}
                            minimumFractionDigits={2}
                            value={desiredCredit}
                          />
                        ),
                      }}
                    />
                  </p>
                )}
                <p className={getClassName('standard-credit-line-content-title')}>
                  <FormattedMessage
                    defaultMessage='Details of your standard credit line offer.'
                    id='sputnik.StandardCreditLineOffer__lvdB9z'
                  />
                </p>
                <p>
                  <FormattedMessage
                    defaultMessage={`
                      {isCrossSell, select,
                        true {Based on the information you've provided, we have confirmed your pre-approval offer. If you accept this offer, we'll do some final verification and set up your account.}
                        other {This is the pre-approved credit line amount for your company. When you accept this offer, we will complete the final review of your application and begin setting up your account. Remember that your balance will be due in full at the end of each billing cycle.}
                      }
                    `}
                    id='sputnik.StandardCreditLineOffer__22HQ5I'
                    values={{
                      isCrossSell,
                    }}
                  />
                </p>
              </div>
              <p className={getClassName('body-caption')}>
                <FormattedMessage
                  defaultMessage='Pre-approval is based on the information currently available and is contingent upon final review of your application.'
                  id='sputnik.StandardCreditLineOffer__VjFJsV'
                />
              </p>
              <section className={getClassName('buttons')}>
                <BasicButton
                  className={getClassName('standard-credit-line-accept')}
                  dataTestId={makeTestId('standard-credit-line-accept')}
                  onClick={handleAcceptClick}
                >
                  <FormattedMessage
                    defaultMessage='Accept offer'
                    id='sputnik.StandardCreditLineOffer__/xro5W'
                  />
                </BasicButton>
                {!isComingFromDualOffer ? (
                  <BasicButton
                    className={getClassName('standard-credit-line-dashboard')}
                    dataTestId={makeTestId('standard-credit-line-dashboard')}
                    type={BASIC_BUTTON_TYPE_FLAT}
                    onClick={handleBackToDashboardClick}
                  >
                    <FormattedMessage
                      defaultMessage='Back to dashboard'
                      id='sputnik.StandardCreditLineOffer__Na5xdK'
                    />
                  </BasicButton>
                ) : (
                  <BasicButton
                    className={getClassName('standard-credit-line-previous')}
                    dataTestId={makeTestId('standard-credit-line-previous')}
                    type={BASIC_BUTTON_TYPE_FLAT}
                    onClick={handlePreviousClick}
                  >
                    <FormattedMessage
                      defaultMessage='Previous'
                      id='sputnik.StandardCreditLineOffer__JJNc3c'
                    />
                  </BasicButton>
                )}
              </section>
            </div>
          </main>
        </div>
        <OneColorSidebar
          className={isCounterOffer ? 'counter-offer-side-bar-image' : ''}
          srcImage={sidebarImage}
        />
      </div>
    </>
  )
}

StandardCreditLineOffer.propTypes = {
  desiredCredit: number,
}

export default StandardCreditLineOffer
