import moment from 'moment'
import { getTokenFromStorage } from './localStorageUtils'
import { getDivvyAuthTokenExpirationDate } from './paseto/paseto'
import { getDecodedToken, getFormattedExpirationDateFromToken } from './jwt'

export const getOriginalAccessToken = () => window.sessionStorage.getItem('originalToken')
export const setOriginalAccessToken = (originalToken: string) =>
  window.sessionStorage.setItem('originalToken', originalToken)
export const unsetOriginalAccessToken = () => window.sessionStorage.removeItem('originalToken')

export const isDivvyAuthToken = (token: string) => {
  const currentToken = token || getTokenFromStorage() || ''
  const claims = getDecodedToken(currentToken)

  return claims?.iss === 'Divvy'
}

export const isTokenExpired = (token: string) => {
  const currentToken = token || getTokenFromStorage()
  if (!currentToken) return true
  const isDivvyAuth = isDivvyAuthToken(currentToken)
  const expDate = isDivvyAuth
    ? getDivvyAuthTokenExpirationDate()
    : getFormattedExpirationDateFromToken(currentToken)?.valueOf()
  if (!expDate) return true
  const currentDateEpoch = moment(new Date().toUTCString()).valueOf()
  return isDivvyAuth ? expDate < currentDateEpoch : !(expDate.valueOf() > new Date().valueOf())
}

// TODO: write tests
