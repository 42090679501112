import React from 'react'
import PropTypes from 'prop-types'
import SkylabThemeProvider from '@divvy-web/skylab.themeprovider'
import { ThemeProvider as EmotionThemeProvider } from 'emotion-theming'

const ThemeProvider = ({ children }) => {
  const themeName = 'bill'
  return (
    <SkylabThemeProvider theme={themeName}>
      {({ theme }) => <EmotionThemeProvider theme={theme}>{children}</EmotionThemeProvider>}
    </SkylabThemeProvider>
  )
}

ThemeProvider.displayName = 'SputnikThemeProvider'

ThemeProvider.propTypes = {
  children: PropTypes.node,
}

export default ThemeProvider
