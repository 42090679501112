import React from 'react'
import { bool, func } from 'prop-types'
import { useMakeTestId } from '@divvy-web/hooks.usenameformatter'
import { FormattedMessage } from '@divvy-web/i18n'
import BasicButton from '@divvy-web/skylab.basicbutton'
import Image from '@divvy-web/skylab.image'
import PasswordTipsContent from './PasswordTipsContent'
import PasswordTipsModal from './PasswordTipsModal'
import PasswordTipsPopsheet from './PasswordTipsPopsheet'

const PasswordTipsContainer = ({ handleCloseClick, isShowing }) => {
  const makeTestId = useMakeTestId('PasswordTips')

  const accountLoginImage = (
    <Image
      alt='magnifying-glass'
      assetName='signup-password-tips-header'
    />
  )

  const title = (
    <FormattedMessage
      defaultMessage='Password tips'
      id='sputnik.PasswordTipsContainer__vAH8wX'
    />
  )

  const actionButton = (
    <BasicButton
      dataTestId={makeTestId('need-tips')}
      onClick={() => handleCloseClick()}
    >
      <FormattedMessage
        defaultMessage='Done'
        id='sputnik.PasswordTipsContainer__JXdbo8'
      />
    </BasicButton>
  )

  return (
    <div>
      <PasswordTipsModal
        actionButton={actionButton}
        headerImage={accountLoginImage}
        isShowing={isShowing}
        title={title}
      >
        <PasswordTipsContent dataTestId='Modal' />
      </PasswordTipsModal>
      <PasswordTipsPopsheet
        actionButton={actionButton}
        headerImage={accountLoginImage}
        isShowing={isShowing}
        subtitle={title}
      >
        <PasswordTipsContent dataTestId='Popsheet' />
      </PasswordTipsPopsheet>
    </div>
  )
}

PasswordTipsContainer.propTypes = { handleCloseClick: func, isShowing: bool }

export default PasswordTipsContainer
