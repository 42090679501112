import React, { useCallback, useContext, useState } from 'react'
import { createSearchParams, URLSearchParamsInit, useNavigate, useParams, useLocation } from 'react-router-dom'
import { FormattedMessage, FormattedCurrency } from '@divvy-web/i18n'
import BasicButton, { BASIC_BUTTON_TYPE_FLAT } from '@divvy-web/skylab.basicbutton'
import TextLink from '@divvy-web/skylab.textlink'
import { TOAST_TYPE_DANGER, useToast } from '@divvy-web/skylab.toast'
import useNameFormatter from '@divvy-web/hooks.usenameformatter'
import Spinner from '@divvy-web/skylab.spinner'
import { useCanary } from '@bill/canary.react'
import { logError, logInfo } from '../../../utils/loggerUtils'
import ApplicationAgreements from '../../../components/ApplicationAgreements'
import { ApplicationAgreementsProps } from '../../../components/ApplicationAgreements/ApplicationAgreements'
import { useSelectedDecision } from '../../gql/mutations/CreditLineOfferSelectedDecision.gql'
import CustomerAssistanceButton from '../../../components/CustomerAssistanceButton'
import { PATHNAME_CONSTS } from '../../../resources/constants'
import OneColorSidebar from '../../../components/OneColorSidebar'
import ImageWrapper from '../../../components/ImageWrapper'
import useDeviceDetect from '../../../hooks/useDeviceDetect'
import { DecisionContext } from '../DecisionsContext'
import { useGetCreditApplicationTilaInfo } from '../../gql/GetCreditApplicationTilaInfo.gql'
import { getSideBarImage } from '../applicationProcessedUtils'
import { standardCreditLineOfferAcceptanceCss } from './standardCreditLineOfferAcceptanceStyles'

const CROSS_SELL = 'cross_sell'

const StandardCreditLineOfferAcceptance = () => {
  const navigate = useNavigate()
  const { appId } = useParams()
  const { pathname } = useLocation()
  const { isMobile } = useDeviceDetect()
  const showDangerToast = useToast(TOAST_TYPE_DANGER) as any
  const shouldShowNewPollingLogic = useCanary('show-new-polling-logic')
  const [getClassName, makeTestId] = useNameFormatter('StandardCreditLineOfferAcceptance')
  const [selectedDecision, { loading: isSelectedDecisionLoading }] = useSelectedDecision({
    onCompleted: () => {
      logInfo({
        attributes: {
          action: 'selectedDecision',
          result: shouldShowNewPollingLogic
            ? `accepts offer for secured credit line and send to polling (/app/${appId}/section/${PATHNAME_CONSTS.ACCEPTING_OFFER})`
            : `accepts offer for secured credit line and send to polling (/app/${appId}/section/processing-application)`,
        },
        eventName: 'StandardCreditLineOfferAcceptance',
      })

      /* toast and navigation to dashboard handled in RocketAnimation for appVersion === 2 */
      appVersion === 2 ? handleAcceptOfferNavigate() : navigate(PATHNAME_CONSTS.DASHBOARD_PATH)
    },
    onError: (e) => {
      logError({
        attributes: {
          action: 'selectedDecision',
          message: e?.message,
          result: 'Error accepting offer for standard credit line',
        },
        eventName: 'StandardCreditLineOfferAcceptance',
      })

      showDangerToast(
        <FormattedMessage
          defaultMessage='There was an error accepting your offer.'
          id='sputnik.StandardCreditLineOfferAcceptance__oRIVFo'
        />,
        {
          autoHideDelay: 5000,
        },
      )
    },
  })
  const { standardLineOffer, desiredCredit, isLoggedInUserAuthorizedSigner, authSignerEmail } =
    useContext(DecisionContext)

  const isSingleOffer = !pathname.includes('credit-line-offers')

  const { approvedAmountInCents, underwritingMethod, id: decisionId, offerType } = standardLineOffer || {}
  const isCrossSell = underwritingMethod === CROSS_SELL
  const isCounterOffer = approvedAmountInCents !== desiredCredit
  const isCrossSellOrCounterOffer = isCounterOffer || isCrossSell

  // for ApplicationAgreements
  const [hasAgreedToTerms, setHasAgreedToTerms] = useState(false)
  const { data, loading: isLoadingTilaInfo } = useGetCreditApplicationTilaInfo({
    fetchPolicy: 'network-only',
    variables: {
      creditApplicationId: appId as string,
      underwritingDecisionUuid: decisionId,
      underwritingOfferType: offerType,
      skipTila: !isLoggedInUserAuthorizedSigner,
    },
    skip: !standardLineOffer,
  })

  const creditApplication = (data?.creditApplication as ApplicationAgreementsProps['creditApplication']) || {}
  const { tila, appVersion } = creditApplication
  const isAppVersion2 = appVersion === 2
  const showAppVersion2 = !isLoadingTilaInfo && isAppVersion2 && data
  const hasAgreedToOfferSummary = !!tila?.meta?.signedAt
  const needsToSignTila = tila && !hasAgreedToOfferSummary
  const isLoading = isLoadingTilaInfo || isSelectedDecisionLoading
  const isAcceptOfferButtonDisabled = isAppVersion2 && ((!hasAgreedToTerms || isLoading || needsToSignTila) as boolean)

  const handleCheckboxClick = () => {
    setHasAgreedToTerms(!hasAgreedToTerms)
  }
  // end for ApplicationAgreements
  const handleAcceptOfferNavigate = useCallback(() => {
    if (!shouldShowNewPollingLogic) {
      navigate({
        pathname: `/app/${appId}/section/processing-application`,
        search: createSearchParams({
          pollingForStatusSince: new Date().getTime(),
          isPollingAfterOfferAcceptance: true,
        } as unknown as URLSearchParamsInit).toString(),
      })
    }

    if (shouldShowNewPollingLogic) {
      navigate({
        pathname: `/app/${appId}/section/${PATHNAME_CONSTS.ACCEPTING_OFFER}`,
        search: createSearchParams({
          pollingForStatusSince: new Date().getTime(),
        } as unknown as URLSearchParamsInit).toString(),
      })
    }
  }, [appId, navigate, shouldShowNewPollingLogic])

  const handleAcceptClick = () => {
    selectedDecision({
      variables: {
        id: decisionId,
        salesforceCreditId: appId as string,
      },
    })
  }

  const handlePreviousClick = () => {
    if (isSingleOffer) {
      navigate(PATHNAME_CONSTS.DASHBOARD_PATH)
    } else {
      navigate(-1)
    }
  }

  if (isLoadingTilaInfo) {
    return (
      <div css={standardCreditLineOfferAcceptanceCss}>
        <Spinner centered />
      </div>
    )
  }

  return (
    <div css={standardCreditLineOfferAcceptanceCss}>
      <CustomerAssistanceButton />
      <div className='main-container fs-unmask'>
        <main className={getClassName('page-content')}>
          <div className={getClassName('body')}>
            <p className={getClassName('title')}>
              <FormattedMessage
                defaultMessage="{isCounterOffer, select,
                    true {We've got an offer for you!}
                    other {{isCrossSell, select,
                      true {Congrats! Your offer is ready.}
                      other {Your standard credit line}
                    }}
                  }"
                id='sputnik.StandardCreditLineOfferAcceptance__iqLKGL'
                values={{
                  isCounterOffer,
                  isCrossSell,
                }}
              />
            </p>
            {isMobile && (
              <div>
                <ImageWrapper
                  alt='person scanning mobile device'
                  // @ts-expect-error component ImageWrapper is missing className as a prop, but is needed here for styling purposes
                  className={getClassName('mobile-standard-offer-image')}
                  imageName={isCounterOffer ? 'counter-offer-mobile' : 'credit-line-offer-mobile'}
                />
              </div>
            )}
            <div
              className={getClassName('approved-amount')}
              data-testid={makeTestId('approved-amount')}
            >
              <FormattedCurrency
                maximumFractionDigits={0}
                minimumFractionDigits={2}
                value={approvedAmountInCents}
              />
            </div>
            <p className={getClassName('how-it-works')}>
              <FormattedMessage
                defaultMessage='How it works:'
                id='sputnik.StandardCreditLineOfferAcceptance__UZgb4A'
              />
            </p>
            <div className={getClassName('how-it-works-list')}>
              <ul>
                <li>
                  <FormattedMessage
                    defaultMessage="Your credit limit is fixed and typically won't change."
                    id='sputnik.StandardCreditLineOfferAcceptance__kT0CVZ'
                  />
                </li>
                <li>
                  <FormattedMessage
                    defaultMessage='You can request credit limit increases right from your dashboard.'
                    id='sputnik.StandardCreditLineOfferAcceptance__BwrSOE'
                  />
                </li>
                <li>
                  <FormattedMessage
                    defaultMessage='Your balance is due in full at the end of each billing cycle.'
                    id='sputnik.StandardCreditLineOfferAcceptance__8LBAFn'
                  />
                </li>
              </ul>
            </div>
            {isCrossSellOrCounterOffer && (
              <div className={getClassName('standard-offer-variation-caption')}>
                <p>
                  <FormattedMessage
                    defaultMessage={`
                      {isCrossSell, select,
                        true {Based on the information you've provided, we have confirmed your pre-approval offer. If you accept this offer, we'll do some final verification and set up your account.}
                        other {{isCounterOffer, select,
                          true {With the information provided in the application we were unable to approve you for the requested amount of {desiredCredit}.}
                          other {}
                        }}
                      }
                    `}
                    id='sputnik.StandardCreditLineOfferAcceptance__OEv4nc'
                    values={{
                      isCrossSell,
                      isCounterOffer,
                      desiredCredit: (
                        <FormattedCurrency
                          maximumFractionDigits={0}
                          minimumFractionDigits={2}
                          value={desiredCredit}
                        />
                      ),
                    }}
                  />
                </p>
              </div>
            )}
            <p className={getClassName('learn-more-wrapper')}>
              <FormattedMessage
                defaultMessage='Still need more info? <textlink>Learn more about standard credit lines</textlink>'
                id='sputnik.StandardCreditLineOfferAcceptance__VjFJsV'
                values={{
                  textlink: (chunks) => (
                    <TextLink
                      className={getClassName('learn-more')}
                      href='https://help.bill.com/direct/s/article/000003577'
                      target='_blank'
                    >
                      {chunks}
                    </TextLink>
                  ),
                }}
              />
            </p>
            {showAppVersion2 && isLoggedInUserAuthorizedSigner && offerType && (
              <ApplicationAgreements
                creditApplication={creditApplication}
                decisionId={decisionId}
                hasAgreedToOfferSummary={hasAgreedToOfferSummary}
                hasAgreedToTerms={hasAgreedToTerms}
                offerType={offerType}
                onCheckboxClick={handleCheckboxClick}
              />
            )}
            {showAppVersion2 && !isLoggedInUserAuthorizedSigner && (
              <div className={getClassName('unauthorized-accept-message')}>
                <FormattedMessage
                  defaultMessage='Only the authorized signer ({email}) can accept this offer.'
                  id='sputnik.StandardCreditLineOfferAcceptance__TdYnmo'
                  values={{
                    email: authSignerEmail,
                  }}
                />
              </div>
            )}
            {!showAppVersion2 && (
              <p className={getClassName('review-disclosure')}>
                <FormattedMessage
                  defaultMessage="By accepting this offer, we'll conduct the final review of your credit application and email you with our approval decision in 3-5 business days."
                  id='sputnik.StandardCreditLineOfferAcceptance__0yOB2W'
                />
              </p>
            )}
            <section className={getClassName('buttons')}>
              <BasicButton
                className={getClassName('previous')}
                dataTestId={makeTestId('previous')}
                type={BASIC_BUTTON_TYPE_FLAT}
                onClick={handlePreviousClick}
              >
                <FormattedMessage
                  defaultMessage={`
                      {isSingleOffer, select,
                        true {Return to dashboard}
                        other {Previous}
                      }
                    `}
                  id='sputnik.StandardCreditLineOfferAcceptance__JJNc3c'
                  values={{
                    isSingleOffer,
                  }}
                />
              </BasicButton>
              {isLoggedInUserAuthorizedSigner && (
                <BasicButton
                  className={getClassName('accept')}
                  dataTestId={makeTestId('accept')}
                  disabled={isAcceptOfferButtonDisabled}
                  showSpinner={isSelectedDecisionLoading}
                  onClick={handleAcceptClick}
                >
                  <FormattedMessage
                    defaultMessage='Accept offer'
                    id='sputnik.StandardCreditLineOfferAcceptance__/xro5W'
                  />
                </BasicButton>
              )}
            </section>
          </div>
        </main>
      </div>
      {!isMobile && <OneColorSidebar srcImage={getSideBarImage(isCounterOffer)} />}
    </div>
  )
}

export default StandardCreditLineOfferAcceptance
