import React from 'react'
import { FormattedMessage } from '@divvy-web/i18n'
import BasicButton, { BASIC_BUTTON_TYPE_FLAT } from '@divvy-web/skylab.basicbutton'
import { TOAST_TYPE_BASIC, useToast } from '@divvy-web/skylab.toast'
import { bool, node, string } from 'prop-types'
import { useAuth } from '../../auth'
import { logInfo } from '../../utils/loggerUtils'
import { logoutBill, navigateToBillLogin } from '../../utils/billUtils'

const LogoutButton = ({ bdcOrgId }) => {
  const { logout, resetAuth } = useAuth()
  const showToast = useToast()

  const handlePostLogout = () => {
    showToast(
      TOAST_TYPE_BASIC,
      <FormattedMessage
        defaultMessage='Log out successful!'
        id='sputnik.LogoutButton__VckeyC'
      />,
      {
        autoHideDelay: 5000,
        dataTestId: 'logout-success-toast',
      },
    )
    logInfo({
      attributes: {
        action: 'handleOnClick',
        result: 'logging user out of the application',
      },
      eventName: 'logout',
    })
  }

  const handleOnClick = async () => {
    if (bdcOrgId) {
      await logoutBill(bdcOrgId)
      resetAuth()
      handlePostLogout()
      navigateToBillLogin(bdcOrgId)
    } else {
      logout()
      handlePostLogout()
    }
  }

  return (
    <BasicButton
      className='logout-btn'
      dataTestId='LogoutButton'
      name='logout'
      type={BASIC_BUTTON_TYPE_FLAT}
      onClick={handleOnClick}
    >
      <FormattedMessage
        defaultMessage='Log out'
        id='sputnik.LogoutButton__PlBReU'
      />
    </BasicButton>
  )
}

LogoutButton.propTypes = {
  bdcOrgId: string,
}

export default LogoutButton
