import React from 'react'
import { FormattedMessage } from '@divvy-web/i18n'
import PollingPageContent from '../../../components/PollingPageContent/PollingPageContent'

const SubmittingApplicationPollingPageContent = () => {
  return (
    <PollingPageContent
      mainText={
        <FormattedMessage
          defaultMessage='Submitting your application'
          id='sputnik.SubmittingApplicationPollingPageContent__S737h6'
        />
      }
      subText={
        <FormattedMessage
          defaultMessage='This could take a couple minutes.'
          id='sputnik.SubmittingApplicationPollingPageContent__zrXnI+'
        />
      }
    />
  )
}

export default SubmittingApplicationPollingPageContent
