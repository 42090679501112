import React from 'react'
import BasicButton, {
  BASIC_BUTTON_TYPE_FLAT,
  BASIC_BUTTON_TYPE_OUTLINED,
  BASIC_BUTTON_TYPE_FILLED,
} from '@divvy-web/skylab.basicbutton'
import { useNavigate, useParams } from 'react-router-dom'
import useNameFormatter from '@divvy-web/hooks.usenameformatter'
import { FormattedMessage } from '@divvy-web/i18n'
import { logInfo } from '../../utils/loggerUtils'
import { PATHNAME_CONSTS } from '../../resources/constants'

interface GoToDashboardButtonProps {
  componentName: string
  dataTestId?: string
  buttonType?: typeof BASIC_BUTTON_TYPE_FLAT | typeof BASIC_BUTTON_TYPE_OUTLINED | typeof BASIC_BUTTON_TYPE_FILLED
}

const GoToDashboardButton = ({
  componentName,
  dataTestId,
  buttonType = BASIC_BUTTON_TYPE_FILLED,
}: GoToDashboardButtonProps) => {
  const [makeTestId, getClassName] = useNameFormatter('GoToDashboardButton')
  const navigate = useNavigate()
  const { appId } = useParams()

  const handleGoToDashboardClick = () => {
    logInfo({
      attributes: {
        action: 'go to dashboard',
        message: `Credit App ID: ${appId}`,
        result: 'Sending user to path: /dashboard',
      },
      eventName: `Navigating to dashboard from ${componentName}`,
    })

    navigate(PATHNAME_CONSTS.DASHBOARD_PATH)
  }

  return (
    <BasicButton
      className={getClassName('')}
      dataTestId={makeTestId(dataTestId || '')}
      type={buttonType}
      onClick={handleGoToDashboardClick}
    >
      <FormattedMessage
        defaultMessage='Go to dashboard'
        id='sputnik.GoToDashboardButton__Oeu69m'
      />
    </BasicButton>
  )
}

export default GoToDashboardButton
