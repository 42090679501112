import React from 'react'
import { FormattedMessage } from '@divvy-web/i18n'
import PollingPageContent from '../../../components/PollingPageContent/PollingPageContent'

const AcceptingOfferPollingPageContent = () => {
  return (
    <PollingPageContent
      mainText={
        <FormattedMessage
          defaultMessage="Hang tight! We're processing your acceptance."
          id='sputnik.AcceptingOfferPollingPageContent__TnYOAx'
        />
      }
      subText={
        <FormattedMessage
          defaultMessage='This can take a minute or two. Please do not close this page.'
          id='sputnik.AcceptingOfferPollingPageContent__WJQRQZ'
        />
      }
    />
  )
}

export default AcceptingOfferPollingPageContent
