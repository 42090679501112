import React from 'react'
import { css } from '@emotion/core'
import { bool, func, node } from 'prop-types'
import BasicButton, { BASIC_BUTTON_TYPE_FLAT } from '@divvy-web/skylab.basicbutton'

const InformativeBanner = ({ children, isDismissable, isVisible, message, onClose, title }) => {
  if (isVisible) {
    return (
      <div
        css={bannerContainer}
        data-testid='InformativeBanner'
      >
        <div css={contentSection}>
          <div css={titleAndMessageCss}>
            {title && <div>{title}</div>}
            {message && <div>{message}</div>}
          </div>
          {children}
        </div>
        {isDismissable && (
          <div css={dismiss}>
            <BasicButton
              data-testid='informative-banner-dismiss'
              icon='close'
              type={BASIC_BUTTON_TYPE_FLAT}
              onClick={onClose}
            />
          </div>
        )}
      </div>
    )
  }

  return null
}

InformativeBanner.propTypes = {
  children: node,
  isDismissable: bool,
  isVisible: bool.isRequired,
  message: node,
  onClose: func,
  title: node,
}

const bannerContainer = css`
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: var(--tri-color-fill-tertiary);
  z-index: 2;
  margin: var(--tri-space-300) 0 var(--tri-space-400) 0;
  height: 88px;
  position: relative;
`

const titleAndMessageCss = ({ type }) => css`
  display: flex;
  flex-direction: column;
  align-items: center;
  ${type.triFontDesktopBodySmallEmphasis}
`

const contentSection = css`
  display: flex;
  flex-direction: column;
  align-items: center;
`

const dismiss = css`
  justify-self: flex-end;
  position: absolute;
  right: 0;
  margin-right: var(--tri-space-400);
`

export default InformativeBanner
