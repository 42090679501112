import React from 'react'
import { FormattedMessage } from '@divvy-web/i18n'
import useNameFormatter from '@divvy-web/hooks.usenameformatter'
import Spinner from '@divvy-web/skylab.spinner'
import { useCanary } from '@bill/canary.react'
import { bool } from 'prop-types'
import useDeviceDetect from '../../hooks/useDeviceDetect'
import ImageWrapper from '../../components/ImageWrapper'
import OneColorSidebar from '../../components/OneColorSidebar/OneColorSidebar'
import PollingPageContent from '../../components/PollingPageContent'
import { contentContainer } from './processingApplicationStyles'

const peopleYogaPath = 'https://app.divvy.co/assets/illustrations/people-yoga'
const peopleYogaSrcSet = `${peopleYogaPath}@3x.png, ${peopleYogaPath}@2x.png, ${peopleYogaPath}@1x.png`

const ProcessingApplication = ({ isPollingAfterOfferAcceptance = false, isAppVersion2 }) => {
  const { isMobile } = useDeviceDetect()
  const [getClassName, makeTestId] = useNameFormatter('ProcessingApplication')
  const showSecuredLineProcessingApplicationPage = useCanary('show-secured-line')

  return (
    <>
      {/* Not refactoring this to use PollingPageContent.js because it'll be removed after feature release */}
      {!showSecuredLineProcessingApplicationPage && !isAppVersion2 && (
        <div
          css={contentContainer}
          data-testid={makeTestId('')}
        >
          <div className='main-content-section fs-unmask'>
            <div
              className='text'
              data-testid={makeTestId('text')}
            >
              {isMobile && (
                <ImageWrapper
                  alt='yoga-mobile'
                  className={getClassName('yoga-mobile-image')}
                  dataTestId={makeTestId('yoga-mobile-image')}
                  imageName='processing-application-mobile'
                />
              )}
              <h1
                className='title'
                data-testid={makeTestId('title')}
              >
                <FormattedMessage
                  defaultMessage='Processing application'
                  id='sputnik.ProcessingApplication__oBmybO'
                />
              </h1>
              <h2
                className='subtitle'
                data-testid={makeTestId('subtitle')}
              >
                <FormattedMessage
                  defaultMessage='The team is busy processing your application. We should have a decision for you shortly.'
                  id='sputnik.ProcessingApplication__YZgIN0'
                />
              </h2>
              <div
                className='caption'
                data-testid={makeTestId('caption')}
              >
                <Spinner
                  isSmall
                  dataTestId={makeTestId('spinner')}
                />
                <FormattedMessage
                  defaultMessage='This can take up to a minute or two.'
                  id='sputnik.ProcessingApplication__UM29H5'
                />
              </div>
            </div>
          </div>
          {!isMobile && <OneColorSidebar srcImage={peopleYogaSrcSet} />}
        </div>
      )}
      {(showSecuredLineProcessingApplicationPage || isAppVersion2) && !isPollingAfterOfferAcceptance && (
        <PollingPageContent
          mainText={
            <FormattedMessage
              defaultMessage='Reviewing your application'
              id='sputnik.ProcessingApplication__S737h6'
            />
          }
          subText={
            <FormattedMessage
              defaultMessage='This could take a couple minutes.'
              id='sputnik.ProcessingApplication__zrXnI+'
            />
          }
        />
      )}
      {(showSecuredLineProcessingApplicationPage || isAppVersion2) && isPollingAfterOfferAcceptance && (
        <PollingPageContent
          mainText={
            <FormattedMessage
              defaultMessage="Hang tight! We're processing your acceptance."
              id='sputnik.ProcessingApplication__TnYOAx'
            />
          }
          subText={
            <FormattedMessage
              defaultMessage='This can take a minute or two. Please do not close this page.'
              id='sputnik.ProcessingApplication__sICtmV'
            />
          }
        />
      )}
    </>
  )
}

ProcessingApplication.propTypes = {
  isPollingAfterOfferAcceptance: bool,
  isAppVersion2: bool,
}

export default ProcessingApplication
