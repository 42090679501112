import { FormTextInput, useForm } from '@divvy-web/skylab.form'
import Icon from '@divvy-web/skylab.icon'
import useNameFormatter from '@divvy-web/hooks.usenameformatter'
import { css } from '@emotion/core'
import { FormattedMessage } from '@divvy-web/i18n'
import { bool, node, string, oneOfType } from 'prop-types'
import useDidMount from '@divvy-web/hooks.usedidmount'
import React, { useCallback, useState } from 'react'
import { getFormattedMaskedPassword } from '../utils'

const MaskedPasswordInput = ({
  alwaysShowError,
  caption,
  dataTestId,
  name,
  placeholder,
  shouldDisablePaste = false,
  ...props
}) => {
  const [getClassName, makeTestId] = useNameFormatter('MaskedPasswordInput')
  const { getFormValue, validationErrors, setFormValue } = useForm()
  const displayFieldName = name
  const underlyingFieldName = displayFieldName?.replace('Display', '')
  const underlyingFieldValue = getFormValue(underlyingFieldName)
  const fieldErrors = validationErrors?.[underlyingFieldName]
  const displayValue = getFormValue(displayFieldName)
  const [hasBlurred, setHasBlurred] = useState(false)
  const [showMaskToggle, setShowMaskToggle] = useState(!!displayValue.length)
  const [showUnmasked, setShowUnmasked] = useState(false)

  const formatUnmaskedValue = useCallback(
    (displayFieldName, value) => {
      setFormValue(displayFieldName, value)
    },
    [setFormValue],
  )

  const formatMaskedValue = useCallback(
    (displayFieldName, value) => {
      setFormValue(displayFieldName, getFormattedMaskedPassword(value))
    },
    [setFormValue],
  )

  useDidMount(() => {
    if (underlyingFieldValue && !showUnmasked) {
      formatMaskedValue(displayFieldName, underlyingFieldValue)
    }
  })

  const handleOnChange = (e) => {
    const { value } = e.target

    if (showUnmasked) {
      setFormValue(displayFieldName, value)
      setFormValue(underlyingFieldName, value)
    } else {
      const currentValueLength = value.length
      const underlyingValueLength = underlyingFieldValue.length
      formatMaskedValue(displayFieldName, value)
      // Set the underlying field value accurately
      if (!currentValueLength) {
        setFormValue(underlyingFieldName, '')
      } else if (currentValueLength === underlyingValueLength - 1) {
        setFormValue(underlyingFieldName, underlyingFieldValue?.slice(0, underlyingValueLength - 1))
      } else if (currentValueLength === underlyingValueLength + 1) {
        setFormValue(underlyingFieldName, underlyingFieldValue?.concat(value?.slice(-1)))
      } else {
        setFormValue(underlyingFieldName, value)
      }
    }
  }

  const handleBlur = (_) => {
    setHasBlurred(true)
    if (!displayValue?.length) {
      setShowMaskToggle(false)
    }
  }

  const handleOnFocus = (_) => {
    setShowMaskToggle(true)
  }

  const handleOnPaste = (e) => {
    if (shouldDisablePaste) {
      e.preventDefault()
    }
  }

  const handleMaskingClick = () => {
    setShowUnmasked((showUnmasked) => !showUnmasked)
    if (displayValue?.length) {
      if (showUnmasked) {
        formatMaskedValue(displayFieldName, displayValue)
      } else {
        if (underlyingFieldValue) {
          formatUnmaskedValue(displayFieldName, underlyingFieldValue)
        }
      }
    }
  }

  const showDefaultText = underlyingFieldName === 'password' || fieldErrors?.length === 1

  const passwordCaption = (
    <div
      className={getClassName('password-caption-wrapper')}
      data-testid={makeTestId('caption')}
    >
      {(hasBlurred || !!displayValue?.length) && (
        <Icon
          dataTestId={makeTestId('password-caption')}
          name={fieldErrors?.length ? 'closeSmall' : 'check'}
        />
      )}
      {showDefaultText ? (
        <FormattedMessage
          defaultMessage='10 or more characters'
          id='sputnik.MaskedPasswordInput__Gk15WE'
        />
      ) : (
        <FormattedMessage
          defaultMessage='Passwords must match'
          id='sputnik.MaskedPasswordInput__/vixtE'
        />
      )}
    </div>
  )

  const maskToggle = (
    <button
      data-testid={makeTestId(`${name}-mask-toggle-button`)}
      type='button'
      onClick={handleMaskingClick}
    >
      <Icon name={showUnmasked ? 'eye' : 'eyeClosed'} />
    </button>
  )

  return (
    <div css={passwordWrapperStyles}>
      <FormTextInput
        alwaysShowError={alwaysShowError}
        caption={passwordCaption}
        dataTestId={makeTestId(dataTestId)}
        rightIcon={showMaskToggle ? maskToggle : null}
        {...props}
        autoComplete='none'
        name={displayFieldName}
        placeholder={placeholder}
        onBlur={handleBlur}
        onChange={handleOnChange}
        onFocus={handleOnFocus}
        onPaste={handleOnPaste}
      />
    </div>
  )
}

MaskedPasswordInput.propTypes = {
  alwaysShowError: bool,
  caption: node,
  dataTestId: string,
  name: string.isRequired,
  placeholder: oneOfType([string, node]).isRequired,
  shouldDisablePaste: bool,
}

const passwordWrapperStyles = css`
  .FormField {
    margin-top: 0;
    width: 256px;
    .TextInput-error-caption {
      display: none;
    }
  }

  .TextInput-caption {
    margin-top: var(--tri-space-50);
    padding-left: 0;
  }

  .MaskedPasswordInput-password-caption-wrapper {
    .Icon {
      margin-bottom: 2px;
    }

    .Icon-closeSmall {
      color: var(--tri-color-icon-danger);
    }

    .Icon-check {
      color: var(--tri-color-icon-success);
    }
  }
`

export default MaskedPasswordInput
