import { css } from '@emotion/core'

export const contentContainer = ({ mq, type }) => css`
  margin: 0;
  padding: 0;
  height: 100%;
  display: flex;
  flex-direction: row;
  position: relative;
  overflow-x: hidden;
  ${mq.xxSmallMaxWidth({
    flexDirection: 'column',
  })}

  .main-content-section {
    flex: 2;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    .text {
      width: 50%;
      ${mq.xSmallMaxWidth({
        width: '75%',
        alignItems: 'center',
        textAlign: 'center',
      })}
      ${mq.xxSmallMaxWidth({
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
      })}
    }

    .title {
      ${type.triFontDesktopTitleLargeEmphasis}
      ${mq.xxSmallMaxWidth({
        ...type.triFontDesktopTitleSmallEmphasis,
      })}
      ${mq.xSmallMaxWidth({
        textAlign: 'center',
      })}
    }

    .subtitle {
      ${type.triFontDesktopBodyLarge}
      color: var(--tri-color-text-secondary);
      padding-right: var(--tri-space-900);
      margin-top: var(--tri-space-400);
      ${mq.xxSmallMaxWidth({
        ...type.triFontDesktopBodyMedium,
      })}
      ${mq.xxSmallMaxWidth({
        marginTop: 'unset',
      })}
      ${mq.xSmallMaxWidth({
        textAlign: 'center',
      })}
      ${mq.mediumMaxWidth({
        marginBottom: 'var(--tri-space-300)',
        paddingRight: 'unset',
      })}
    }

    .caption {
      display: flex;
      align-items: center;
      margin-top: var(--tri-space-400);
      ${type.triFontDesktopCaptionMediumEmphasis}
      span:first-of-type {
        margin-right: var(--tri-space-200);
      }
      ${mq.xxSmallMaxWidth({
        ...type.triFontDesktopBodySmallEmphasis,
      })}
      ${mq.xxSmallMaxWidth({
        marginTop: 'unset',
      })}
      ${mq.xSmallMaxWidth({
        flexDirection: 'column-reverse',
        gap: 'var(--tri-space-200)',
        textAlign: 'center',
      })}
    }
  }

  .sidebar-image {
    position: absolute;
    bottom: 0;
    ${mq.xSmallMinWidth({
      maxWidth: '440px',
      right: '-90px',
    })}
    ${mq.mediumMinWidth({
      maxWidth: '625px',
      right: '-130px',
    })}
      ${mq.largeMinWidth({
      maxWidth: '700px',
      right: '-145px',
    })};
    ${mq.xLargeMinWidth({
      maxWidth: '850px',
      right: '-200px',
    })};
    ${mq.xxLargeMinWidth({
      maxWidth: '1076px',
      right: '-220px',
    })};
  }

  // This className is defined in the ImageWrapper component in src/components/ImageWrapper
  .program-image {
    max-width: 213px;
  }
`
