import React from 'react'
import { Route, Routes } from 'react-router-dom'
import { useCanary } from '@bill/canary.react'
import Dashboard from './pages/Dashboard'
import NotGoodFit from './pages/NotGoodFit'
import SignUp from './pages/SignUp'
import VerifyCode from './pages/VerifyCode'
import BdcLanding from './pages/BdcLanding'
import NotFound from './pages/NotFound'
import FormWrapper from './components/FormWrapper/FormWrapper'
import PageErrorBoundary from './components/PageErrorBoundary'
import RocketAnimation from './pages/RocketAnimation'
import Root from './components/Root'
import ApplicationProcessed from './pages/ApplicationProcessed'
import RequireAuth from './components/Routes/RequireAuth'
import SignIn from './pages/SignIn'
import ApplicationSubmitted from './pages/ApplicationSubmitted/ApplicationSubmitted'
import SubmissionErrorPage from './pages/ReviewAndSign/SubmissionErrorPage'
import GoodFitPage from './pages/GoodFitPage/GoodFitPage'
import MobileDocumentUpload from './pages/MobileDocumentUpload/MobileDocumentUpload'
import VerifyPassword from './pages/VerifyPassword/VerifyPassword'
import ResetPassword from './pages/ResetPassword/ResetPassword'
import RightFitSurveyPage from './pages/RightFitSurveyPage'
import { PATHNAME_CONSTS } from './resources/constants'
import SubmittingApplicationPollingPage from './pages/SubmittingApplicationPollingPage'
import AcceptingOfferPollingPage from './pages/AcceptingOfferPollingPage'
import ReviewingOfferPollingPage from './pages/ReviewingOfferPollingPage'

const ApplicationRoutes = () => {
  const shouldShowNewPollingLogic = useCanary('show-new-polling-logic')
  return (
    <Root>
      <PageErrorBoundary>
        <Routes>
          <Route
            element={<SignIn />}
            path={PATHNAME_CONSTS.AUTH_PATH}
          />
          <Route
            element={<SignIn />}
            path={PATHNAME_CONSTS.MIGRATION_PATH}
          />
          <Route
            element={<RightFitSurveyPage />}
            path={PATHNAME_CONSTS.RIGHT_FIT_SURVEY_PATH}
          />
          <Route
            element={<GoodFitPage />}
            path={PATHNAME_CONSTS.GOOD_FIT_PATH}
          />
          <Route
            element={<VerifyCode />}
            path={PATHNAME_CONSTS.VERIFY_EMAIL_PATH}
          />
          <Route
            element={<VerifyPassword />}
            path={PATHNAME_CONSTS.VERIFY_PASSWORD_PATH}
          />
          <Route
            element={<RequireAuth component={ResetPassword} />}
            path='/reset-password'
          />
          <Route
            element={<BdcLanding />}
            path='/bdc-landing'
          />
          <Route
            element={<NotGoodFit />}
            path={PATHNAME_CONSTS.NOT_GOOD_FIT_PATH}
          />
          {/* mobile document upload page implements its own auth logic */}
          <Route
            element={<MobileDocumentUpload />}
            path={PATHNAME_CONSTS.MOBILE_DOCUMENT_UPLOAD_PATH}
          />
          <Route
            element={<RequireAuth component={SignUp} />}
            path={PATHNAME_CONSTS.SIGN_UP_PATH}
          />
          <Route
            element={<RequireAuth component={Dashboard} />}
            path='/'
          />
          <Route
            element={<RequireAuth component={Dashboard} />}
            path={PATHNAME_CONSTS.DASHBOARD_PATH}
          />
          <Route path='/app/:appId/section'>
            <Route
              index
              element={<RequireAuth component={NotFound} />}
            />
            <Route
              element={<RequireAuth component={ApplicationSubmitted} />}
              path='success/*'
            />
            {!shouldShowNewPollingLogic && (
              <Route
                element={<RequireAuth component={RocketAnimation} />}
                path={PATHNAME_CONSTS.PROCESSING_APPLICATION}
              />
            )}
            {shouldShowNewPollingLogic && (
              <>
                <Route
                  element={<RequireAuth component={SubmittingApplicationPollingPage} />}
                  path={PATHNAME_CONSTS.SUBMITTING_APPLICATION}
                />
                <Route
                  element={<RequireAuth component={ReviewingOfferPollingPage} />}
                  path={PATHNAME_CONSTS.REVIEWING_OFFER}
                />
                <Route
                  element={<RequireAuth component={AcceptingOfferPollingPage} />}
                  path={PATHNAME_CONSTS.ACCEPTING_OFFER}
                />
              </>
            )}
            <Route
              element={<RequireAuth component={SubmissionErrorPage} />}
              path='submission-error'
            />
            <Route
              element={<RequireAuth component={ApplicationProcessed} />}
              path='processed/*'
            />
          </Route>
          <Route
            element={<RequireAuth component={FormWrapper} />}
            path='/app/:appId/section/*'
          />
          <Route
            element={<RequireAuth component={ApplicationSubmitted} />}
            path='/app/success'
          />
          {/* No routes below this line */}
          <Route
            exact
            element={<NotFound />}
            path='*'
          />
        </Routes>
      </PageErrorBoundary>
    </Root>
  )
}

export default ApplicationRoutes
