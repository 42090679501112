import React, { useContext, useCallback, useState, useEffect, useMemo } from 'react'
import { FormattedMessage } from '@divvy-web/i18n'
import { string, func, node, bool } from 'prop-types'
import { FormTextInput, FormContext } from '@divvy-web/skylab.form'
import TextLink from '@divvy-web/skylab.textlink'
import { css } from '@emotion/core'
import FormElementWrapper from './FormElementWrapper'

const FormEmailInput = ({ ...props }) => {
  const [suggestion, setSuggestion] = useState(null)
  const { getFormValue, getValidationErrors, setFormValue } = useContext(FormContext)
  const validationErrors = getValidationErrors(props.name)
  const email = props.value || getFormValue(props.name)

  useEffect(() => {
    setSuggestion(null)
  }, [email])

  const onBlurHandler = useCallback(() => {
    if (!validationErrors && email && email.includes('@')) {
      setSuggestion(emailFixSuggestion(email))
    }
  }, [email, validationErrors])

  const acceptSuggestionHandler = useCallback(() => {
    if (props.onChange) {
      props.onChange({ target: { name: props.name, value: suggestion } })
    } else {
      setFormValue(props.name, suggestion)
    }
  }, [props, setFormValue, suggestion])

  const calculatedErrorCaption = useMemo(
    () =>
      validationErrors ||
      (suggestion && (
        <div css={suggestionCss}>
          <FormattedMessage
            defaultMessage='Did you mean {suggestion}?'
            id='sputnik.FormEmailInput__fVtDSi'
            values={{
              suggestion: <TextLink onClick={acceptSuggestionHandler}>{suggestion}</TextLink>,
            }}
          />
        </div>
      )),
    [acceptSuggestionHandler, suggestion, validationErrors],
  )

  return (
    <FormElementWrapper isSuggestion={!!suggestion}>
      <FormTextInput
        {...props}
        errorCaption={props.errorCaption || calculatedErrorCaption}
        hasError={props.hasError || !!calculatedErrorCaption}
        onBlur={onBlurHandler}
      />
    </FormElementWrapper>
  )
}

const suggestionCss = css`
  color: var(--tri-color-text-primary);
`

FormEmailInput.propTypes = {
  errorCaption: node,
  hasError: bool,
  name: string.isRequired,
  onBlur: func,
  onChange: func,
  readOnly: bool,
  value: string,
}

export const emailFixSuggestion = (email) => {
  const [username, domain] = email.split('@')
  const [preTld, tld] = domain.split('.', 2)

  if (commonDomains.includes(domain)) {
    return null
  }

  const suggestedDomain = commonDomains.find((commonDomain) => {
    const distance = stringDistance(commonDomain, domain)
    const areShortDomains = commonDomain.length <= 7 && domain.length <= 7
    return (areShortDomains && distance <= 1) || (!areShortDomains && distance <= 2)
  })

  if (suggestedDomain) {
    return username + '@' + suggestedDomain
  } else if (tld === 'con' || !tld) {
    return username + '@' + preTld + '.com'
  } else {
    return null
  }
}

const stringDistance = function (s1, s2, maxOffset) {
  // sift4: https://siderite.blogspot.com/2014/11/super-fast-and-accurate-string-distance.html
  if (maxOffset === undefined) {
    maxOffset = 5 // default
  }

  if (!s1 || !s1.length) {
    if (!s2) {
      return 0
    }
    return s2.length
  }

  if (!s2 || !s2.length) {
    return s1.length
  }

  var l1 = s1.length
  var l2 = s2.length

  var c1 = 0 // cursor for string 1
  var c2 = 0 // cursor for string 2
  var lcss = 0 // largest common subsequence
  var localCs = 0 // local common substring
  var trans = 0 // number of transpositions ('ab' vs 'ba')
  var offsetArr = [] // offset pair array, for computing the transpositions

  while (c1 < l1 && c2 < l2) {
    if (s1.charAt(c1) === s2.charAt(c2)) {
      localCs++
      var isTrans = false
      // see if current match is a transposition
      var i = 0
      while (i < offsetArr.length) {
        var ofs = offsetArr[i]
        if (c1 <= ofs.c1 || c2 <= ofs.c2) {
          // when two matches cross, the one considered a transposition is the one with the largest difference in offsets
          isTrans = Math.abs(c2 - c1) >= Math.abs(ofs.c2 - ofs.c1)
          if (isTrans) {
            trans++
          } else {
            if (!ofs.trans) {
              ofs.trans = true
              trans++
            }
          }
          break
        } else {
          if (c1 > ofs.c2 && c2 > ofs.c1) {
            offsetArr.splice(i, 1)
          } else {
            i++
          }
        }
      }
      offsetArr.push({
        c1: c1,
        c2: c2,
        trans: isTrans,
      })
    } else {
      lcss += localCs
      localCs = 0
      if (c1 !== c2) {
        c1 = c2 = Math.min(c1, c2) // using min allows the computation of transpositions
      }
      // if matching characters are found, remove 1 from both cursors (they get incremented at the end of the loop)
      // so that we can have only one code block handling matches
      for (var j = 0; j < maxOffset && (c1 + j < l1 || c2 + j < l2); j++) {
        if (c1 + j < l1 && s1.charAt(c1 + j) === s2.charAt(c2)) {
          c1 += j - 1
          c2--
          break
        }
        if (c2 + j < l2 && s1.charAt(c1) === s2.charAt(c2 + j)) {
          c1--
          c2 += j - 1
          break
        }
      }
    }
    c1++
    c2++
    // this covers the case where the last match is on the last token in list, so that it can compute transpositions correctly
    if (c1 >= l1 || c2 >= l2) {
      lcss += localCs
      localCs = 0
      c1 = c2 = Math.min(c1, c2)
    }
  }
  lcss += localCs
  return Math.round(Math.max(l1, l2) - lcss + trans) // add the cost of transpositions to the final result
}

const commonDomains = [
  'gmail.com',
  'yahoo.com',
  'icloud.com',
  'outlook.com',
  'hotmail.com',
  'aol.com',
  'mail.com',
  'protonmail.com',
]

export default FormEmailInput
