import React, { useState, useEffect, useCallback } from 'react'
import { useQuery } from '@apollo/client'
import { useLocation, useNavigate, useParams, useSearchParams } from 'react-router-dom'
import { FormattedMessage } from '@divvy-web/i18n'
import { TOAST_TYPE_SUCCESS, useToast } from '@divvy-web/skylab.toast'
import { CreditApplicationStatus } from '_gql'
import GetApplicationStatus from '../../pages/gql/GetApplicationStatus.gql'
import { PATHNAME_CONSTS } from '../../resources/constants'
import useApplicantTokenOnMount from '../../hooks/useApplicantTokenOnMount'
import AcceptingOfferPollingPageContent from './AcceptingOfferPollingPageContent'

// const CREDIT_DECISION_POLLING_MAX_TIMEOUT = window.__DivvyEnvironment.PROCESSING_MAX_TIMEOUT || 500
const STATUS_POLLING_MAX_TIMEOUT = 120000
const POLLING_RATE = 3000

const calculateStatusTimeoutRemaining = (pollingForStatusSince) =>
  STATUS_POLLING_MAX_TIMEOUT - (new Date().getTime() - pollingForStatusSince)

const AcceptingOfferPollingPage = () => {
  /* make sure we have a token before we start polling */
  useApplicantTokenOnMount()
  const navigate = useNavigate()
  const showSuccessToast = useToast(TOAST_TYPE_SUCCESS) as any
  const { pathname, search: urlSearch } = useLocation()
  const { appId } = useParams()

  const [search] = useSearchParams()

  const pollingForStatusSince = search?.get('pollingForStatusSince')
    ? parseInt(search?.get('pollingForStatusSince') as string)
    : null
  const [statusTimer, setStatusTimer] = useState(0)

  const {
    data: statusData,
    startPolling: startPollingForStatus,
    stopPolling: stopPollingForStatus,
  } = useQuery(GetApplicationStatus, {
    fetchPolicy: 'no-cache',
    skip: !pollingForStatusSince,
    variables: { creditApplicationId: appId },
  })

  const { creditApplication } = statusData || {}
  const isApprovedStatus = creditApplication?.status === CreditApplicationStatus.APPROVED

  const handleSubmissionError = useCallback(() => {
    stopPollingForStatus()
    window.sessionStorage.removeItem('submissionPendingUrl')
    navigate(`/app/${appId}/section/submission-error`)
  }, [appId, navigate, stopPollingForStatus])

  // save url to redirect back to it in case user leaves this page
  useEffect(() => {
    if (urlSearch.includes('pollingForStatusSince')) {
      //TODO: can we use something else than useLocation?
      window.sessionStorage.setItem('submissionPendingUrl', pathname + urlSearch)
    }
  }, [appId, pathname, pollingForStatusSince, urlSearch])

  // handle polling for status
  useEffect(() => {
    if (pollingForStatusSince) {
      startPollingForStatus(POLLING_RATE)
      if (statusTimer >= calculateStatusTimeoutRemaining(pollingForStatusSince)) {
        handleSubmissionError()
      } else {
        setTimeout(() => setStatusTimer(statusTimer + POLLING_RATE), POLLING_RATE)
      }
    }
  }, [handleSubmissionError, pollingForStatusSince, startPollingForStatus, statusTimer])
  const originalStatus = search.get('originalStatus')
  const statusHasChanged = creditApplication?.status && originalStatus && creditApplication?.status !== originalStatus
  useEffect(() => {
    if (isApprovedStatus) {
      stopPollingForStatus()
      navigate(PATHNAME_CONSTS.DASHBOARD_PATH, { replace: true })
      showSuccessToast(
        <FormattedMessage
          defaultMessage='Offer accepted'
          id='sputnik.RocketAnimation__IB0Vm6'
        />,
        {
          autoHideDelay: 5000,
        },
      )
    }
  }, [isApprovedStatus, navigate, showSuccessToast, statusHasChanged, stopPollingForStatus])

  return <AcceptingOfferPollingPageContent />
}

export default AcceptingOfferPollingPage
